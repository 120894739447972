<template>
  <div id="goods_out">
    <div id="panel-header">
      <span class="top-title">出库列表</span>
      <Button type="primary" icon="md-add" class="goods-out-create" @click="goodsOutCreate('clientFormItem')" v-if="aCheck.rightCheck('operate_warehouse_out')">
        手动出库
      </Button>
    </div>
    <!--列表筛选条件-->
    <div id="filter-bar">
      <Card dis-hover>
        <Form ref="list.search_item" :model="list.search_item" inline>
          <FormItem prop="main_no">
            <Input @on-blur="searchSubmit" v-model="list.search_item.main_no" placeholder="请填写出库编号" class="w-200"/>
          </FormItem>

          <FormItem prop="status">
            <Select @on-change="searchSubmit" v-model="list.search_item.status" placeholder="请选择出库状态" filterable class="w-200">
              <Option v-for="item in list.all_status" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>

          <FormItem prop="link_no">
            <Input @on-blur="searchSubmit" v-model="list.search_item.link_no" placeholder="请填写关联单号" class="w-200"/>
          </FormItem>

          <FormItem prop="created_at">
            <DatePicker type="daterange" @on-change="timeChange" placement="bottom-end"
                        placeholder="请选择创建时间" v-model="list.search_item.created_at" class="w-200"></DatePicker>
          </FormItem>

          <FormItem prop="out_type">
            <Select @on-change="searchSubmit" v-model="list.search_item.out_type" placeholder="请选择出库类型" filterable class="w-200">
              <Option v-for="item in list.out_type_info" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>

          <FormItem style="margin-right: 0;" >
            <Button type="text" class="main-font-color" @click="cancelSearch('list.search_item')">清除</Button>
          </FormItem>

        </Form>
      </Card>
    </div>

    <!--列表-->
    <div id="list" class="goods_out">
      <Table :columns="list.columns_info" :data="list.data">
        <template slot-scope="{ row }" slot="main_no">
          <strong @click="checkOrderInfo(row)" class="pointer">{{ row.main_no }}</strong>
        </template>
        <template slot-scope="{ row }" slot="status">
          <span :style="getStatusColor(row.status)">{{ getStatusText(row.status) }}</span>
        </template>
        <template slot-scope="{ row }" slot="out_type">
          <span>{{ getOutTypeText(row.out_type) }}</span>
        </template>
        <template slot-scope="{ row }" slot="created_at">
          <span>{{ row.created_at.substring(0, 10) }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="action">
          <!--审核通过情况下显示-->
          <Button v-show="[1,2].includes(row.status)" type="text" size="small" v-if="aCheck.rightCheck('operate_warehouse_out')"
                  :style="getStatusColor(row.status)" @click="checkOrderInfo(row)">
            <Icon type="ios-checkmark-circle"/>
            下架
          </Button>
          <!--已发货-->
          <Button v-show="row.status==6" type="text" size="small" :style="getStatusColor(row.status)"
                  @click="checkOrderInfo(row)">
            <Icon type="ios-checkmark-circle"/>
            查看
          </Button>

          <Button v-if="[1,2].includes(row.out_type) && aCheck.rightCheck('operate_warehouse_out')" v-show="row.status==5" type="text" size="small" :style="getStatusColor(row.status)"
                  @click="shipOut(row)">
            <Icon type="ios-checkmark-circle"/>
            转发货
          </Button>

          <Button v-if="[3,4, 5].includes(row.out_type)" v-show="row.status==5" type="text" size="small" style="color:#19be6b;"
                  @click="checkOrderInfo(row)">
            <Icon type="ios-checkmark-circle"/>
            查看
          </Button>

          <Button v-show="row.status==3" type="text" size="small" :style="getStatusColor(row.status)"
                  @click="finishOrderInfo(row)" v-if="aCheck.rightCheck('operate_warehouse_out')">
            <Icon type="ios-bus"/>
            出库
          </Button>

          <!--已取消-->
          <Button v-show="row.status==4" type="text" size="small" :style="getStatusColor(row.status)"
                  @click="checkOrderInfo(row)">
            <span class="disabled-color fs-12">------</span>
          </Button>
        </template>
      </Table>
      <div class="page">
        <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"
              :current="list.page" show-sizer @on-page-size-change="pageSizeChange" :page-size-opts="list.page_size_opts"/>
      </div>

    </div>

    <!--查看下架信息-->
    <Modal v-model="check_order.modal" :title="check_order.modal_title" width="1028">
      <div class="modalForm">
        <Spin size="large" fix v-show="check_order.spin_show"></Spin>
        <Form ref="clientFormItem" :model="clientFormItem" :label-width="100" label-colon>
          <Row :gutter="12">
            <Col span="12">
              <FormItem label="出库单号" prop="main_no">
                <span>{{ clientFormItem.main_no }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="出库类型" prop="out_type">
                <span>{{ getOutTypeText(clientFormItem.out_type) }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="12">
            <Col span="12">
              <FormItem label="出库数量" prop="out_num">
                <span>{{ clientFormItem.out_num }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="关联单号" prop="link_no">
                <span>{{ clientFormItem.link_no }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="12">
            <Col span="12">
              <FormItem label="单据状态" prop="status">
                <span :style="getStatusColor(clientFormItem.status)">{{ getStatusText(clientFormItem.status) }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="备注" prop="remark">
                <span>{{ clientFormItem.remark }}</span>
              </FormItem>
            </Col>
          </Row>

          <Row :class="clientFormItem.detail.outStartList.length ? 'mt2 display-block':''" v-show="clientFormItem.detail.outStartList.length">
            <Card :bordered="false">
              <p slot="title">待下架货品</p>
              <Button type="primary" slot="extra" size="small" @click="listOutBulk" class="f12" v-if="aCheck.rightCheck('operate_warehouse_out')">
                <Icon type="ios-checkmark-circle"/>
                批量下架
              </Button>
              <Table :columns="check_order.out_start_table" :data="clientFormItem.detail.outStartList" @on-selection-change="getSelectionChange">
                <template slot-scope="{ row }" slot="thumbnail_url">
                  <p><img v-show="row.thumbnail_url" class="preview-img" :src="row.thumbnail_url" alt=""></p>
                </template>
                <template slot-scope="{ row }" slot="action">
                  <Button type="primary" size="small" @click="pushOutHousePosition(row, clientFormItem.id)" v-if="aCheck.rightCheck('operate_warehouse_out')">
                    <Icon type="ios-checkmark-circle"/>
                    下架
                  </Button>
                </template>
              </Table>
            </Card>
          </Row>

          <Row :class="clientFormItem.detail.outEndList.length ? 'mt2 display-block':''" v-show="clientFormItem.detail.outEndList.length">
            <Card :bordered="false">
              <p slot="title">已下架货品</p>
              <Table :columns="check_order.out_end_table" :data="clientFormItem.detail.outEndList">
                <template slot-scope="{ row }" slot="thumbnail_url">
                  <p><img v-show="row.thumbnail_url" class="preview-img" :src="row.thumbnail_url" alt=""></p>
                </template>
                <template slot-scope="{ row }" slot="house_info">
                  <Breadcrumb separator=">">
                    <BreadcrumbItem class="fs-12">{{ row.house_name }}</BreadcrumbItem>
                    <BreadcrumbItem class="fs-12">{{ row.area_name }}</BreadcrumbItem>
                    <BreadcrumbItem class="fs-12">{{ row.position_name }}</BreadcrumbItem>
                  </Breadcrumb>
                </template>
              </Table>
            </Card>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleResetOrderModal">关闭</Button>
        <Button v-show="clientFormItem.status==3" type="primary" @click="finishOrderInfo(clientFormItem)" v-if="aCheck.rightCheck('operate_warehouse_out')">
          出库
        </Button>
      </div>
    </Modal>

    <!--下架操作-->
    <Modal v-model="push_out.modal" :title="push_out.modal_title" width="1028">
      <div class="modalForm">
        <Form ref="push_out.item" :model="push_out.item" :label-width="120" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="货品编号" prop="part_no">
                <span>{{ push_out.item.part_no }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="货品名称" prop="part_name">
                <span>{{ push_out.item.part_name }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="缩略图" prop="thumbnail_url">
                <p><img :src="push_out.item.thumbnail_url" alt=""></p>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="待下架数量" prop="goods_num">
                <span>{{ push_out.item.goods_num }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="下架仓库">
                <Select v-model="push_out.item.house_id" placeholder="请选择仓库" @on-change="getHouseAreaAllOnChange">
                  <Option v-for="o in common.house_all" :value="o.house_id" :key="o.house_id">
                    {{ o.house_name }}({{ o.house_no }})
                  </Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="下架库区">
                <Select v-model="push_out.item.area_id" placeholder="请先选择仓库" @on-change="getHousePositionAllOnChange">
                  <Option v-for="item in common.area_all" :value="item.area_id" :key="item.area_id">
                    {{ item.area_name }}({{ item.area_no }})
                  </Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="下架库位">
                <Select v-model="push_out.item.position_id" placeholder="请先选择仓库和库区">
                  <Option v-for="o in common.position_all" :value="o.position_id"
                          :key="o.position_id">{{ o.position_name }}({{ o.position_no }})
                  </Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="下架数量">
                <Input v-model="push_out.item.out_num" type="number" placeholder="请填写出库数量"/>
              </FormItem>
            </Col>
          </Row>

          <Row class="mt2 display-block" v-show="stockInfoList.length">
            <Card :bordered="false">
              <p slot="title">货品仓库位置列表</p>
              <Table :columns="goods_stock_position_info.out_already_table" :data="stockInfoList">
                <template slot-scope="{ row }" slot="thumbnail_url">
                  <p><img v-show="row.thumbnail_url" class="preview-img" :src="row.thumbnail_url" alt=""></p>
                </template>
              </Table>
            </Card>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="pushOutModalCancel()">关闭</Button>
        <Button type="success" :loading="push_out.modal_loading" @click="pushOutSure()">确认下架
        </Button>
      </div>
    </Modal>

    <!--添加手动出库-->
    <Modal v-model="goods_out_create.modal" :title="goods_out_create.modal_title" width="1028">
      <div class="modalForm">
        <Form v-show="!common.view_loading" ref="clientFormItem" :model="goods_out_create.item" :label-width="100" :rules="goods_out_create.ruleInline" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="出库单号" prop="main_no">
                <Input v-model="goods_out_create.item.main_no" disabled placeholder="请填写出库单号"></Input>
              </FormItem>
            </Col>

            <FormItem label="出库类型" prop="in_type">
              <span>{{ getOutTypeText(goods_out_create.item.in_type) }}</span>
            </FormItem>
          </Row>

          <Row :gutter="20">
            <Col span="12">
              <FormItem label="出库数量" prop="in_num">
                <span>{{ goods_out_create.item.in_num }}</span>
              </FormItem>
            </Col>

            <Col span="12">
              <FormItem label="备注" prop="remark">
                <Input v-model="goods_out_create.item.remark" placeholder="请填写备注"></Input>
              </FormItem>
            </Col>
          </Row>

          <Row class="mt2 display-block">
            <Card dis-hover>
              <p slot="title">待下架货品</p>
              <Button type="primary" slot="extra" size="small" @click="addGoods()" class="f12">
                <Icon type="md-add"/>
                选择货品
              </Button>
              <Table :columns="goods_out_create.columns" :data="goods_out_create.item.goods_set">
                <template slot-scope="{ row }" slot="part_no">
                  <p><span>{{row.part_no}}</span></p>
                </template>
                <template slot-scope="{ row }" slot="thumbnail_url">
                  <p><img v-show="row.thumbnail_url" class="preview-img" :src="row.thumbnail_url" alt=""></p>
                </template>

                <template slot-scope="{ row, index }" slot="goods_num">
                  <p>
                    <i-input type="number" v-model="row.goods_num" @on-change="setGoodsOutNum(row, index)"/>
                  </p>
                </template>

                <template slot-scope="{ row, index }" slot="action">
                  <Button type="text" size="small" class="fs-12" @click="deleteGoodsSelected(row, index)">
                    <Icon type="ios-trash"/>
                    删除
                  </Button>
                </template>
              </Table>
            </Card>
          </Row>
        </Form>
        <Spin fix v-show="common.view_loading" size="large"></Spin>
      </div>
      <div slot="footer">
        <Button @click="handleReset()">关闭</Button>
        <Button type="primary" :loading="goods_out_create.modal_loading" @click="saveGoodsIn()">保存
        </Button>
      </div>
    </Modal>

    <!--批量下架操作-->
    <Modal v-model="push_out_batch.modal" :title="push_out_batch.modal_title" width="1128">
      <div class="modalForm">
        <Form ref="push_out.item" :model="push_out.item" :label-width="120" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="下架仓库">
                <Select v-model="push_out_batch.item.house_id" placeholder="请选择仓库"
                        @on-change="getHouseAreaAll">
                  <Option v-for="o in common.house_all" :value="o.house_id" :key="o.house_id">
                    {{ o.house_name }}({{ o.house_no }})
                  </Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="下架库区">
                <Select v-model="push_out_batch.item.area_id" placeholder="请先选择仓库"
                        @on-change="getHousePositionAll">
                  <Option v-for="item in common.area_all" :value="item.area_id" :key="item.area_id">
                    {{ item.area_name }}({{ item.area_no }})
                  </Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="下架库位">
                <Select v-model="push_out_batch.item.position_id" placeholder="请先选择仓库和库区" @on-change="setBatchGoodsOutPosition">
                  <Option v-for="o in common.position_all" :value="o.position_id"
                          :key="o.position_id">{{ o.position_name }}({{ o.position_no }})
                  </Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="pushOutModalCancel">关闭</Button>
        <Button type="success" :loading="push_out_batch.modal_loading" @click="pushOutBatchSure">确认下架</Button>
      </div>
    </Modal>


    <SelectGoods ref="select_goods" @selected="getSelectedGoods" :select_goods="select_goods"/>
  </div>
</template>

<script>
import SelectGoods from '@/components/common/SelectGoods.vue';

import {
  createShipOutByGoodsOutId
} from "@/api/goods/goods";
import {
  getHouseAreaAll,
  getHousePositionAll,
  pushOutFinish,
  getGoodsOutMainNo,
  goodsOutByHands,
  apiPushOutBatch
} from '@/api/goods/goodsOut';
import NP from "number-precision";
import {pushInBatch} from "@/api/goods/goodsIn";

export default {
  name: "GoodsOut",
  data() {
    return {
      select_goods: {
        modal: false,
        count_flag: 0,
        goods_type: 0
      },
      goods_out_create: {
        modal: false,
        modal_title: "手动出库操作",
        item: {
          main_no: '',
          in_type: 5,
          in_num: 0,
          remark: '',
          goods_set: [],
        },
        ruleInline: {},
        modal_loading: false,
        columns: [
          {
            title: '货品编号',
            key: 'part_no',
            slot: 'part_no',
            align: 'center'
          },
          {
            title: '货品名称',
            key: 'part_name',
            align: 'center'
          },
          {
            title: '缩略图',
            key: 'thumbnail_url',
            slot: 'thumbnail_url',
            align: 'center'
          },
          {
            title: '待出库数量',
            key: 'goods_num',
            slot: 'goods_num',
            align: 'center'
          },
          {
            title: '仓库库存',
            key: 'stock_all',
            align: 'center'
          },
          {
            title: '操作',
            key: 'action',
            slot: 'action',
            align: 'center'
          }
        ],
      },

      clientFormItem: {
        id: '',
        main_no: '',
        out_type: '',
        link_no: '',
        link_id: 0,
        link_house_id: 0,
        out_num: '',
        status: '',
        remark: '',
        client_name: '',
        client_id:'',
        detail: {
          outStartList: [],
          outEndList: [],
        },
      },
      stockInfoList: [],

      //查看下架细节信息
      check_order: {
        modal: false,
        spin_show: false,
        modal_title: '查看订单信息',
        out_start_table: [
          {
            type: 'selection',
            width: 60,
            align: 'center'
          },
          {
            title: '货品编号',
            key: 'part_no',
            align: 'center'
          },
          {
            title: '货品名称',
            key: 'part_name',
            align: 'center'
          },
          {
            title: '缩略图',
            key: 'thumbnail_url',
            slot: 'thumbnail_url',
            align: 'center'
          },
          {
            title: '待出库数量',
            key: 'goods_num',
            align: 'center'
          },
          {
            title: '仓库库存',
            key: 'stock_all',
            align: 'center'
          },
          {
            title: '操作',
            key: 'action',
            slot: 'action',
            align: 'center'
          }
        ],
        out_end_table: [
          {
            title: '货品编号',
            key: 'part_no',
            align: 'center'
          },
          {
            title: '货品名称',
            key: 'part_name',
            align: 'center'
          },
          {
            title: '缩略图',
            key: 'thumbnail_url',
            slot: 'thumbnail_url',
            align: 'center'
          },
          {
            title: '已下架数量',
            key: 'num',
            align: 'center'
          },
          {
            title: '来源仓库位置',
            key: 'house_info',
            slot: 'house_info',
            width: 240,
            align: 'center'
          },
        ],
      },
      goods_stock_position_info: {
        modal: false,
        spin_show: false,
        modal_title: '查看订单信息',
        out_already_table: [
          {
            title: '货品编号',
            key: 'part_no',
            align: 'center'
          },
          {
            title: '货品名称',
            key: 'part_name',
            align: 'center'
          },
          {
            title: '缩略图',
            key: 'thumbnail_url',
            slot: 'thumbnail_url',
            align: 'center'
          },
          {
            title: '库存',
            key: 'num',
            align: 'center'
          },
          {
            title: '仓库',
            key: 'house_name',
            align: 'center'
          },
          {
            title: '库区',
            key: 'area_name',
            align: 'center'
          },
          {
            title: '库位',
            key: 'position_name',
            align: 'center'
          },

        ],
      },
      //下架操作
      push_out: {
        modal: false,
        modal_title: '出库下架操作',
        modal_loading: false,
        item: {
          house_id: 0,//仓库
          area_id: '',//库区
          position_id: '',//库位
          part_no: '',//货品编号
          part_name: '',//货品名称
          thumbnail_url: '',//缩略图
          goods_num: '',//待下架数量
          goods_out_id: '',//出库单ID
          goods_id: '',//货品ID
          out_num: '',//出库数量
          link_id: 0,
          out_type: '',
        }
      },

      //通用信息
      common: {
        house_all: [],
        area_all: [],
        position_all: [],
      },
      //列表
      list: {
        page_size_opts: [10, 15, 20, 30],
        columns_info: [
          {
            title: '出库单号',
            key: 'main_no',
            slot: 'main_no',
            align: 'center'
          },
          {
            title: '出库状态',
            key: 'status',
            slot: 'status',
            align: 'center'
          },
          {
            title: '出库类型',
            key: 'out_type',
            slot: 'out_type',
            align: 'center'
          },
          {
            title: '出库数量',
            key: 'out_num',
            align: "center",
          },
          {
            title: '关联单号',
            key: 'link_no',
            align: 'center'
          },
          {
            title: '备注',
            key: 'remark',
            align: 'center'
          },
          {
            title: '创建时间',
            slot: 'created_at',
            key: 'created_at',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
            width: 160,
          }
        ],
        data: [],
        total: 0,
        size: 0,
        page: 1,
        search_item: {
          main_no: '',
          out_type: '',
          created_at: '',
          link_no: '',
        },
        all_status: [
          {
            "id": 1,
            "name": '待下架'
          },
          {
            "id": 2,
            "name": '下架中'
          },
          {
            "id": 3,
            "name": '已下架'
          },
          {
            "id": 4,
            "name": '已取消'
          },
          {
            "id": 5,
            "name": '已出库'
          },
          {
            "id": 6,
            "name": '转发货'
          },
        ],
        out_type_info: [
          {
            "id": 1,
            "name": "销售"
          },
          {
            "id": 2,
            "name": "采购退货"
          },
          {
            "id": 3,
            "name": "领料"
          },
          {
            "id": 4,
            "name": "调拨"
          }
        ],
        page_size_key: 'goods_out_page_size_key',
      },

      push_out_batch: {
        modal: false,
        modal_title: '批量出库下架操作',
        modal_loading: false,
        item: {
          goods_out_id: 0,
          house_id: '',//仓库
          area_id: '',//库区
          position_id: '',//库位
          goods_info: [],//货品信息
          out_type: 0,
          link_id: 0,
        }
      }
    };
  },
  methods: {
    //批量确认下架
    pushOutBatchSure() {
      this.push_out_batch.modal_loading = true;

      if (this.push_out_batch.item.goods_info.length< 0) {
        this.$Message.error('批量下架货品不能为空');
        this.push_out.modal_loading = false;
        return;
      }

      let param = {
        goods_out_id:this.push_out_batch.item.goods_out_id,
        position_id: this.push_out_batch.item.position_id,
        area_id: this.push_out_batch.item.area_id,
        house_id: this.push_out_batch.item.house_id,
        goods_info:this.push_out_batch.item.goods_info,
        main_no: this.clientFormItem.main_no,
        out_type: this.push_out_batch.item.out_type,
        link_id: this.push_out_batch.item.link_id,
      };

      apiPushOutBatch(param).then(res => {
        if (res.data.err_no === 0) {
          this.$Message.success(res.data.results);

          //更新下架情况
          this.getGoodsOutOrderById(this.push_out_batch.item.goods_out_id);

          this.push_out_batch.modal = false;
          //更新列表信息
          this.getOrderList();

          this.push_out_batch.item.goods_info = [];
        } else {
          this.$Message.error(res.data.err_msg);
          this.push_out_batch.modal_loading = false;
        }
      }).catch(error => {
        this.$Message.error('操作错误');
        this.push_out_batch.modal_loading = false;
      });
    },
    pushOutModalCancel() {
      this.push_out.modal = false;
      this.push_out_batch.modal = false;
    },
    setStorageBatchGoodsOut(key, value) {
      switch (key) {
        case 'house':
          localStorage.setItem('batch_goods_out_house', value);
          break;
        case 'area':
          localStorage.setItem('batch_goods_out_area', value);
          break;
        case 'position':
          localStorage.setItem('batch_goods_out_position', value);
          break;
      }
    },
    setBatchGoodsOutPosition: function (position_id) {
      this.setStorageBatchGoodsOut('house', this.push_out_batch.item.house_id)
      this.setStorageBatchGoodsOut('area', this.push_out_batch.item.area_id)
      this.setStorageBatchGoodsOut('position', position_id);
    },
    getStorageBatchGoodsOut(key) {
      let rs = '';
      switch (key) {
        case 'house':
          rs = localStorage.getItem('batch_goods_out_house');
          break;
        case 'area':
          rs = localStorage.getItem('batch_goods_out_area');
          break;
        case 'position':
          rs = localStorage.getItem('batch_goods_out_position');
          break;
      }

      return rs;
    },
    listOutBulk() {
      console.log(this.push_out_batch.item.goods_info)
      //判断是否有选中
      if (this.push_out_batch.item.goods_info.length<=0) {
        // this.$Message.error('请选择下架货品！');
        console.log(this.clientFormItem.detail.outStartList);
        for (let i=0; i<this.clientFormItem.detail.outStartList.length; i++) {
          let tmp = {
            goods_id : this.clientFormItem.detail.outStartList[i].goods_id,
            goods_num : this.clientFormItem.detail.outStartList[i].goods_num,
          };
          this.push_out_batch.item.goods_info.push(tmp);
        }
      }

      this.push_out_batch.item.goods_out_id = this.clientFormItem.id;
      this.push_out_batch.item.out_type = this.clientFormItem.out_type;
      this.push_out_batch.item.link_id = this.clientFormItem.link_id;
      if (this.clientFormItem.link_house_id) {
        this.push_out_batch.item.house_id = this.clientFormItem.link_house_id;
        this.push_out_batch.item.area_id = '';
        this.push_out_batch.item.position_id = '';
      } else {
        this.push_out_batch.item.house_id = parseFloat(this.getStorageBatchGoodsOut('house'));
        this.push_out_batch.item.area_id = parseFloat(this.getStorageBatchGoodsOut('area'));
        this.push_out_batch.item.position_id = parseFloat(this.getStorageBatchGoodsOut('position'));
      }

      if (this.push_out_batch.item.house_id) {
        this.getHouseAreaAll(this.push_out_batch.item.house_id)
      }

      if (this.push_out_batch.item.area_id) {
        this.getHousePositionAll(this.push_out_batch.item.area_id)
      }

      this.push_out_batch.modal = true;
      this.push_out_batch.modal_loading = false;
    },
    getSelectionChange(item) {
      this.push_out_batch.item.goods_info = [];
      for (let i=0; i<item.length; i++) {
        let tmp = {
          goods_id : item[i].goods_id,
          goods_num : item[i].goods_num,
        };
        this.push_out_batch.item.goods_info.push(tmp);
      }
    },
    saveGoodsIn() {
      this.goods_out_create.modal_loading = true;
      if (this.goods_out_create.item.goods_set.length <= 0) {
        this.$Message.error('请选择需要下架的货品！');
        this.goods_out_create.modal_loading = false;
        return false;
      }

      for (let i = 0; i < this.goods_out_create.item.goods_set.length; i++) {
        if (this.goods_out_create.item.goods_set[i].goods_num == undefined || parseFloat(this.goods_out_create.item.goods_set[i].goods_num) <=0 ) {
          this.$Message.error('请正确填写下架货品的数量，不能为空和负数！');
          this.goods_out_create.modal_loading = false;
          return false;
        }
        if (this.goods_out_create.item.goods_set[i].goods_num == undefined || parseFloat(this.goods_out_create.item.goods_set[i].goods_num) > parseFloat(this.goods_out_create.item.goods_set[i].stock_all) ) {
          this.$Message.error('请正确填写下架货品的数量，下架数量不能超过库存数量！');
          this.goods_out_create.modal_loading = false;
          return false;
        }
      }

      goodsOutByHands(this.goods_out_create.item).then(res => {
        if (res.data.err_no == 0) {
          this.$Message.success(res.data.results);
          this.goods_out_create.modal_loading = false;
          this.goods_out_create.modal = false;

          this.getOrderList();
        } else {
          this.$Message.error(res.data.err_msg);
          this.goods_out_create.modal_loading = false;
        }
      }).catch(err => {
        this.$Message.error('操作异常');
        this.goods_out_create.modal_loading = false;
      })
    },
    setGoodsOutNum(row, index) {
      this.goods_out_create.item.goods_set[index].goods_num = row.goods_num;
      this.setGoodsOutNumTotal();
    },
    deleteGoodsSelected(row, index) {
      this.goods_out_create.item.goods_set.splice(index, 1);
      this.setGoodsOutNumTotal();
    },
    setGoodsOutNumTotal() {
      let total = 0;
      for (let i = 0; i < this.goods_out_create.item.goods_set.length; i++) {
        if (this.goods_out_create.item.goods_set[i].goods_num !== undefined) {
          total =  NP.plus(total, this.goods_out_create.item.goods_set[i].goods_num);
        }
      }

      this.goods_out_create.item.in_num = total;
    },
    //获取选中的货品
    getSelectedGoods(val) {
      //设置选中的物料
      let selected_goods = val;
      let set_goods = this.goods_out_create.item.goods_set;

      if (selected_goods.length) {
        for (let i = 0, len = selected_goods.length; i < len; i++) {
          let added_flag = false;

          //排除已添加的
          for (let j = 0, jlen = set_goods.length; j < jlen; j++) {
            if (set_goods[j].id == selected_goods[i].id) {
              added_flag = true;
              break;
            }
          }

          //如果未添加
          if (!added_flag) {
            set_goods.push(selected_goods[i]);
          }
        }
      }
    },
    addGoods() {//添加物料
      this.$refs.select_goods.select_goods.goods_type = this.select_goods.goods_type;
      this.$refs.select_goods.showGoodsList();
    },
    goodsOutCreate(name) {
      this.goods_out_create.modal = true;
      this.goods_out_create.item.goods_set = [];
      getGoodsOutMainNo().then(res => {
        if (res.data.err_no == 0) {
          this.goods_out_create.item.main_no = res.data.results;
        }
      })
    },
    pageSizeChange(page_size) {
      this.list.size = page_size;
      localStorage.setItem(this.list.page_size_key, page_size);
      this.getOrderList();
    },
    //获取出库来源文案
    getOutTypeText($out_type) {
      let rs = '';
      switch ($out_type) {
        case 1:
          rs = '销售';
          break;
        case 2:
          rs = '采购退货';
          break;
        case 3:
          rs = '领料';
          break;
        case 4:
          rs = '调拨';
          break;
        case 5:
          rs = '手动';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getStatusColor(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = 'color:#ed4014';
          break;
        case 2:
          rs = 'color:#2775ff';
          break;
        case 3:
          rs = 'color:#2775ff';
          break;
        case 4:
          rs = 'color:#c5c8ce';
          break;
        case 6:
          rs = 'color:#19be6b';
          break;
        case 5:
          rs = 'color:#2db7f5';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getStatusText(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = '待下架';
          break;
        case 2:
          rs = '下架中';
          break;
        case 3:
          rs = '已下架';
          break;
        case 4:
          rs = '已取消';
          break;
        case 5:
          rs = '已出库';
          break;
        case 6:
          rs = '转发货';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },

    //弹出框重置
    handleReset() {
      this.goods_out_create.modal = false;
    },

    //获取出库单信息
    getGoodsOutOrderById($goods_out_id) {
      this.check_order.spin_show = true;
      this.$axios.get('/api/goods/getGoodsOutOrderById?id=' + $goods_out_id)
          .then((res) => {
            if (res.data.err_no == 0) {
              this.clientFormItem.id = res.data.results.id;
              this.clientFormItem.main_no = res.data.results.main_no;
              this.clientFormItem.out_type = res.data.results.out_type;
              this.clientFormItem.link_no = res.data.results.link_no;
              this.clientFormItem.link_id = res.data.results.link_id;
              this.clientFormItem.link_house_id = res.data.results.link_house_id;
              this.clientFormItem.out_num = parseFloat(res.data.results.out_num);
              this.clientFormItem.status = res.data.results.status;
              this.clientFormItem.remark = res.data.results.remark;
              this.clientFormItem.client_name = res.data.results.client_name;
              this.clientFormItem.client_id = res.data.results.client_id;

              for (let i = 0; i < res.data.results.detail.outStartList.length; i++) {
                res.data.results.detail.outStartList[i].goods_num = parseFloat(res.data.results.detail.outStartList[i].goods_num);
                res.data.results.detail.outStartList[i].stock_all = parseFloat(res.data.results.detail.outStartList[i].stock_all);
              }

              for (let i = 0; i < res.data.results.detail.outEndList.length; i++) {
                res.data.results.detail.outEndList[i].num = parseFloat(res.data.results.detail.outEndList[i].num);
                res.data.results.detail.outEndList[i].stock_all = parseFloat(res.data.results.detail.outEndList[i].stock_all);
              }
              this.clientFormItem.detail.outStartList = res.data.results.detail.outStartList;
              this.clientFormItem.detail.outEndList = res.data.results.detail.outEndList;
              this.check_order.spin_show = false;
            }
          });
    },

    //列表搜索
    searchSubmit() {
      this.list.page = 1;
      this.getOrderList();
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.list.page = 1;
      this.getOrderList();
    },

    //获取订单列表
    getOrderList() {
      let param = {
        page: this.list.page,
        main_no: this.list.search_item.main_no,
        status: this.list.search_item.status,
        created_at: this.list.search_item.created_at,
        link_no: this.list.search_item.link_no,
        out_type: this.list.search_item.out_type,
        size: this.list.size,
      };
      this.$axios.post('/api/goods/getGoodsOutList', param).then((res) => {
        if (res.data.err_no == 0) {
          for (let i=0; i<res.data.results.list.length; i++) {
            res.data.results.list[i].out_num = parseFloat(res.data.results.list[i].out_num);
          }
          this.list.data = res.data.results.list;
          this.list.total = res.data.results.total;
          this.list.size = res.data.results.size;
        }
      });
    },
    changePage(page) {
      this.list.page = page;
      this.getOrderList();
    },

    //进行下架操作
    checkOrderInfo(row) {
      this.getGoodsOutOrderById(row.id);

      this.check_order.modal = true;
      this.push_out.modal_loading = false;
    },

    shipOut(row) {
      this.$Modal.confirm({
        title: "发货操作",
        content: '货品已出库完成，确定发货？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          let param = {
            goods_out_id: row.id,
          };
          createShipOutByGoodsOutId(param).then((response) => {
            if (response.data.err_no === 0) {
              this.$Message.success(response.data.results);
              this.getOrderList();
            } else {
              this.$Message.error(response.data.err_msg);
            }
          }).catch((error) => {
            this.$Message.error('操作失败');
          });
        },
        onCancel: () => {
        }
      });
    },

    //出库完成
    finishOrderInfo(row) {
      this.$Modal.confirm({
        title: "出库操作",
        content: '货品已下架完成，确定出库？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          pushOutFinish({goods_out_id: row.id}).then((response) => {
              if (response.data.err_no === 0) {
                this.$Message.success(response.data.results);
                this.getOrderList();
                this.check_order.modal = false;
              } else {
                this.$Message.error(response.data.err_msg);
              }
          }).catch((error) => {
            this.$Message.error('操作失败');
          });
        },
        onCancel: () => {
        }
      });

    },

    //弹出框关闭操作
    handleResetOrderModal() {
      this.check_order.modal = false;
    },

    //下架
    pushOutHousePosition(row, goods_out_id) {
      this.push_out.item.area_id = '';
      this.push_out.item.house_id = this.clientFormItem.link_house_id;
      this.push_out.item.position_id = '';
      this.push_out.item.part_no = row.part_no;
      this.push_out.item.part_name = row.part_name;
      this.push_out.item.thumbnail_url = row.thumbnail_url;
      this.push_out.item.goods_num = row.goods_num;
      this.push_out.item.out_num = row.goods_num;
      this.push_out.item.goods_out_id = goods_out_id;
      this.push_out.item.goods_id = row.goods_id;
      this.push_out.item.link_id = this.clientFormItem.link_id;
      this.push_out.item.out_type = this.clientFormItem.out_type;

      this.push_out.modal_loading = false;
      this.push_out.modal = true;

      let param = {
        goods_id: row.goods_id,
      };
      this.$axios.post('/api/goods/getGoodsStockInfo', param).then((res) => {
        if (res.data.err_no === 0) {
          for (let i=0; i<res.data.results.list.length; i++) {
            res.data.results.list[i].num = parseFloat(res.data.results.list[i].num);
          }
          this.stockInfoList = res.data.results.list;
          this.selectPosition(this.stockInfoList)
        } else {
          this.$Message.error(res.data.err_msg);
        }
      });
    },

    //下架仓库选中，如果货品只在一个仓库的库位中，则默认选中
    selectPosition(stock) {
      if (this.push_out.item.house_id && stock.length != 1) {
        this.getHouseAreaAll(this.push_out.item.house_id, 0);
      }

      if (stock.length != 1) {
        return false;
      }

      let data = stock[0];
      if (!this.push_out.item.house_id) {
        this.getHouseAreaAll(data.house_id, data.area_id);
        this.getHousePositionAll(data.area_id, data.position_id);
      } else {
        if (data.house_id == this.push_out.item.house_id) {
          this.getHouseAreaAll(data.house_id, data.area_id);
          this.getHousePositionAll(data.area_id, data.position_id);
        } else {
          this.getHouseAreaAll(this.push_out.item.house_id, 0);
        }
      }
    },

    //确认下架
    pushOutSure() {
      this.push_out.modal_loading = true;

      //检查下架数量是否大于出库数量
      if (parseFloat(this.push_out.item.out_num) > parseFloat(this.push_out.item.goods_num)) {
        this.$Message.error('下架数量不能大于出库数量');
        this.push_out.modal_loading = false;
        return;
      }

      let param = {
        goods_out_id: this.push_out.item.goods_out_id,
        goods_id: this.push_out.item.goods_id,
        house_id: this.push_out.item.house_id,
        area_id: this.push_out.item.area_id,
        position_id: this.push_out.item.position_id,
        num: this.push_out.item.out_num,
        main_no: this.clientFormItem.main_no,
        link_id: this.push_out.item.link_id,
        out_type: this.push_out.item.out_type,
      };

      this.$axios.post('/api/goods/pushOutSure', param)
          .then((response) => {
            if (response.data.err_no === 0) {
              this.$Message.success(response.data.results);

              //更新下架情况
              this.getGoodsOutOrderById(this.push_out.item.goods_out_id);

              this.push_out.modal = false;
              //更新列表信息
              this.getOrderList();
            } else {
              this.$Message.error(response.data.err_msg);
              this.push_out.modal_loading = false;
            }
          })
          .catch((error) => {
            this.$Message.error('操作失败');
            this.push_out.modal_loading = false;
          });
    },

    //获取所有可用仓库列表
    getWarehouseAll: function () {
      this.$axios.get('/api/setting/getWarehouseAll').then((response) => {
        if (response.data.err_no === 0) {
          this.common.house_all = response.data.results;
        }
      });
    },
    getHouseAreaAllOnChange(change) {
      if (change === undefined) return;

      if (this.clientFormItem.link_house_id && change != this.clientFormItem.link_house_id) {
        this.$Message.error('出库仓库已指定，请不要随意更改，以免造成出库异常！！！');
        this.push_out.item.house_id = this.clientFormItem.link_house_id;
        return false;
      }

      getHouseAreaAll(change).then(response => {
        if (response.data.err_no === 0) {
          this.common.area_all = response.data.results;
          this.push_out.item.area_id = 0;
        }
      });
    },
    getHousePositionAllOnChange: function (change) {
      if (change === undefined) return;

      getHousePositionAll(change).then(response => {
        if (response.data.err_no === 0) {
          this.common.position_all = response.data.results;
          this.push_out.item.position_id = 0;
        }
      });
    },
    //获取仓库所有库区
    getHouseAreaAll: function (house_id, area_id) {
      getHouseAreaAll(house_id).then(response => {
            if (response.data.err_no === 0) {
              this.common.area_all = response.data.results;
              this.push_out.item.area_id = area_id;
              this.push_out.item.house_id = house_id;
            }
          });
    },
    //获取仓库对应库区的所有库位
    getHousePositionAll: function (area_id, position_id) {
      getHousePositionAll(area_id).then(response => {
            if (response.data.err_no === 0) {
              this.common.position_all = response.data.results;
              this.push_out.item.position_id = position_id;
            }
      });
    },
    timeChange(dateRange) {
      if (dateRange[0] && dateRange[1]) {
        this.list.search_item.created_at = dateRange;
      } else {
        this.list.search_item.created_at = "";
      }

      this.searchSubmit();
    },
    setSelectedPageSize() {
      let size = localStorage.getItem(this.list.page_size_key);
      this.list.size = size ? (size > 50 ? 40 : parseInt(size)) : 0;
    },
  },
  components: {
    SelectGoods,
  },
  mounted() {
    this.setSelectedPageSize();
    this.getOrderList();
    this.getWarehouseAll();
  },
  created() {
    this.aCheck.plateName = 'product_manage';
  },
};
</script>

<style scoped>
#goods_out {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

#panel-header {
  position: relative;
  border: none;
}

.modalForm {
  width: 100%;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

.client_create {
  position: absolute;
  right: 0;
  top: -8px;
}

#goods_out .ivu-btn-small {
  font-size: 12px !important;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#list .page {
  margin-top: 16px;
}

.goods-out-create {
  position: absolute;
  right: 0;
  top: -8px;
}
</style>
<style>
#goods_out .ivu-card {
  background-color: #fafafa;
}

#goods_out .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#goods_out .ivu-form-item {
  margin-bottom: 0;
}

#list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#list .ivu-page {
  float: right;
}

.goods_out .ivu-table-overflowX, .ivu-table-tip {
  overflow-x: hidden;
}
</style>
