<template>
  <!--图片预览-->
  <Modal v-model="lib.modal" width="1080" title="素材库" @on-ok="setSelectPicture">
    <Form ref="search.item" :model="search.item" inline>
      <FormItem prop="title">
        <i-input @on-blur="searchSubmit" v-model="search.item.title" class="w-200" placeholder="请填写标题"/>
      </FormItem>

      <FormItem>&nbsp;
        <Button type="text" class="main-font-color" @click="cancelSearch('search.item')">
          清除
        </Button>
      </FormItem>
    </Form>

      <CheckboxGroup v-model="select_item" @on-change="onChangeSelectFlag">
        <Row class="list-info">
          <Col span="6" v-for="(item,index) in list.data">
            <div class="align-center pointer padding-4">
              <div class="align-center"><img @click="changeSelectFlag(item)" :src="item.tmp_url" alt="" style='max-width:100%;height:100px;'></div>
              <div style="overflow:hidden;white-space:nowrap;"><Checkbox :label="item.id">{{item.title}}</Checkbox></div>
            </div>
          </Col>
        </Row>
      </CheckboxGroup>
    <Row style="flex-flow:row-reverse wrap">
      <Page size="small" :total="list.total" :current.sync="list.page"
            :page-size="list.size" show-elevator
            show-sizer
            @on-change="changePage" @on-page-size-change="changePageSize"
            class="mt10"/>
    </Row>
  </Modal>
</template>

<script>
import {apiGetImgLibList} from "@/api/setting/pictureLib";

export default {
  name: "PictureLib",
  data() {
    return {
      thumbnailUrl:'?x-oss-process=image/resize,m_mfit,h_200,w_200',
      lib: {
        modal: false,
      },
      search: {
        item: {
          title: ''
        }
      },
      select_item:[],
      select_limit: 3,
      click_data:[],
      list: {
        data: [],
        total:0,
        size: 0,
        page:1,
      }
    }
  },
  watch: {
    'select_item': {
      handler(newVal, oldVal) {
        this.setSelectMsg(newVal)
      },
      deep: true,
    }
  },
  methods: {
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.list.page = 1;
      this.getList();
    },
    searchSubmit() {
      this.list.page = 1;
      this.getList();
    },
    setSelectPicture() {
      let data = [];
      for (let i=0; i< this.select_item.length; i++) {
        if (this.click_data[this.select_item[i]] != undefined) {
          data.push(this.click_data[this.select_item[i]])
        }
      }
      this.$emit('selected', data);
    },
    setSelectMsg(newVal) {
      if (newVal.length > this.select_limit) {
        while (newVal.length > this.select_limit) {
          this.select_item.shift();
        }
      }
    },
    changeSelectFlag(item) {
      if (this.select_item.includes(item.id)) {
        this.select_item.splice(this.select_item.indexOf(item.id), 1);
      } else {
        this.select_item.push(item.id);
      }

      this.click_data[item.id] = item.img_url;
    },
    onChangeSelectFlag(e) {
      for (let i = 0; i < this.list.data.length; i++) {
        for (let j=0; j<e.length; j++) {
          if (this.list.data[i].id == e[j]) {
            this.click_data[this.list.data[i].id] = this.list.data[i].img_url;
          }
        }
      }
    },
    changePageSize(pageSize) {
      this.list.size = pageSize;
      this.list.page = 1;
      this.getList();
    },
    changePage(page) {
      this.list.page = page;
      this.getList();
    },
    openSelect() {
      this.click_data = [];
      this.getList();
      this.lib.modal = true;
    },
    getList() {
      let param = {
        page: this.list.page,
        title: this.search.item.title,
        size: this.list.size
      };

      apiGetImgLibList(param).then(res => {
        this.list.data = res.data.results.list;
        for (let i = 0; i < this.list.data.length; i++) {
          this.list.data[i].select_flag = false;
          this.list.data[i].tmp_url = this.list.data[i].img_url + this.thumbnailUrl;
        }
        this.list.total = res.data.results.total;
        this.list.size = res.data.results.size;
      })
    }
  }
}
</script>

<style scoped>
.list-info {
  border: 1px solid #f5f5f5;
  padding: 10px;
  border-radius: 4px;
}
</style>
