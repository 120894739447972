import { render, staticRenderFns } from "./StockTransfer.vue?vue&type=template&id=b7703b82&scoped=true&"
import script from "./StockTransfer.vue?vue&type=script&lang=js&"
export * from "./StockTransfer.vue?vue&type=script&lang=js&"
import style0 from "./StockTransfer.vue?vue&type=style&index=0&id=b7703b82&scoped=true&lang=css&"
import style1 from "./StockTransfer.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7703b82",
  null
  
)

export default component.exports