<template>
  <div id="pick_goods" class="module-body">
    <div id="panel-header" class="panel-header">
      <span class="top-title">领料货品</span>

      <div id="filter-bar" class="filter-bar">
        <Card dis-hover>
          <Form ref="list_search_item" :model="list.search_item" inline>
            <FormItem prop="goods_name">
              <Input @on-blur="searchSubmit()" v-model="list.search_item.goods_name" placeholder="请填写货品名称" class="w-200"/>
            </FormItem>

            <FormItem prop="main_no">
              <Input @on-blur="searchSubmit()" v-model="list.search_item.main_no" placeholder="请填写货品编号" class="w-200"/>
            </FormItem>

            <FormItem>&nbsp;
              <Button type="text" class="main-font-color" @click="cancelSearch('list_search_item')">清除</Button>
            </FormItem>
          </Form>
        </Card>
      </div>

      <div id="list" class="main-list">
        <Table :columns="list.columns" :data="list.data">

          <template slot-scope="{ row }" slot="main_no">
            <strong class="pointer">{{row.main_no}}</strong>
          </template>

          <template slot-scope="{ row }" slot="type">
            <span v-for="item in common.all_type" v-if="item.id == row.type">{{ item.name }}</span>
          </template>

          <template slot-scope="{ row }" slot="goods_type">
            <span>{{ getGoodsType(row.goods_type) }}</span>
          </template>

        </Table>
        <div class="page">
          <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"
                :current="list.page" show-sizer @on-page-size-change="pageSizeChange" :page-size-opts="list.page_size_opts"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {
  getPickGoodsList
} from '@/api/goods/pickGoods';

export default {
  name: "PickGoods",
  data() {
    return {
      common: {
        all_type: [
          {
            "id": 1,
            "name": "生产领料"
          },
          {
            "id": 2,
            "name": "行政领料"
          },
          {
            "id": 3,
            "name": "设计领料"
          }
        ],//所有领料类型
      },
      list: {
        page_size_key: 'pick_goods_page_size_key',
        page_size_opts: [10, 15, 20, 30],
        search_item: {
          main_no: '',
          goods_name: '',
        },
        columns: [
          {
            title: '领料类型',
            key: 'type',
            slot: 'type',
            align: 'center',
          },
          {
            title: '货品编号',
            key: 'main_no',
            slot: 'main_no',
            align: 'center',
          },
          {
            title: '货品名称',
            key: 'goods_name',
            align: 'center',
          },
          {
            title: '货品类型',
            key: 'goods_type',
            slot: 'goods_type',
            align: 'center',
          },
          {
            title: '货品数量',
            key: 'goods_num',
            align: 'center',
          },
        ],
        data: [],
        page: 1,
        size: 0,
      }
    }
  },
  methods: {
    getGoodsType(type) {
      let name = '';
      switch (type) {
        case 1:
          name = '物料';
          break;
        case 2:
          name = '成品';
          break;
        case 3:
          name = '半成品';
          break;
        case 4:
          name = '虚拟';
          break;
      }
      return name;
    },
    pageSizeChange(page_size) {
      this.list.size = page_size;
      localStorage.setItem(this.list.page_size_key, page_size);
      this.getMainList();
    },
    changePage(page) {
      this.list.page = page;
      this.getMainList();
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.list.page = 1;
      this.getMainList();
    },
    searchSubmit() {
      this.list.page = 1;
      this.getMainList();
    },
    getMainList() {
      let param = {
        page: this.list.page,
        size: this.list.size,
        main_no: this.list.search_item.main_no,
        goods_name: this.list.search_item.goods_name,
      };

      getPickGoodsList(param).then((res) => {
        for (let i = 0; i < res.data.results.list.length; i++) {
          res.data.results.list[i].goods_num = parseFloat(res.data.results.list[i].goods_num);
        }
        this.list.data = res.data.results.list;
        this.list.total = res.data.results.total;
        this.list.size = res.data.results.size;
      });
    },
    setSelectedPageSize() {
      let size = localStorage.getItem(this.list.page_size_key);
      this.list.size = size ? (size > 50 ? 40 : parseInt(size)) : 0;
    },
  },
  mounted() {
    this.setSelectedPageSize();
    this.getMainList();
  },
}
</script>

<style scoped>
#pick_goods .ivu-card {
  background-color: #fafafa;
}

#pick_goods .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#list .page {
  margin-top: 16px;
}

</style>
<style>
#pick_goods .ivu-form-item {
  margin-bottom: 0;
}
</style>
