import {apiGetSysUploadType} from "@/api/common/sysUploadType";

import WellCache from "well-cache";

let well_cache = new WellCache({prefix: localStorage.getItem('user_uniq_id'), mode: 'IDB'})
let cache_time = 1000 * 60 * 30;
let cache_key = 'sys-upload-type';


export async function getUploadType() {
    const UploadType = await well_cache.getSync(cache_key);

    if (UploadType.data != null) {
        if (parseInt(UploadType.data.time) + cache_time < parseInt(new Date().getTime())) {
            await resetUploadType();
        }
        return JSON.parse(UploadType.data.results);
    } else {
        return resetUploadType();
    }
}

export async function resetUploadType() {
    let res = await apiGetSysUploadType();

    if (res.data.err_no == 0) {
        let data = {};
        data.results = JSON.stringify(res.data.results);
        data.time = new Date().getTime();
        well_cache.save(cache_key, data);
    }

    return res.data.results;
}
