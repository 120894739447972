<template>
  <div id="ship-container" class="module-body">
    <div id="panel-header" class="panel-header">
      <span class="top-title">发货列表</span>
    </div>
    <div id="filter-bar" class="filter-bar">
      <Card dis-hover>
        <Form ref="list_search_item" :model="list.search_item" inline>
          <FormItem  prop="main_no">
            <Input @on-blur="searchSubmit" v-model="list.search_item.main_no" placeholder="请填写发货单号" class="w-200"/>
          </FormItem>

          <FormItem  prop="sale_no">
            <Input @on-blur="searchSubmit" v-model="list.search_item.sale_no" placeholder="请填写销售单号" class="w-200"/>
          </FormItem>

          <FormItem  prop="out_no">
            <Input @on-blur="searchSubmit" v-model="list.search_item.out_no" placeholder="请填写出库单号" class="w-200"/>
          </FormItem>

          <FormItem prop="created_at">
            <DatePicker type="daterange" @on-change="timeChange" placement="bottom-end"
                        placeholder="请选择创建时间" v-model="list.search_item.created_at" class="w-200"></DatePicker>
          </FormItem>

          <FormItem>&nbsp;
            <Button type="text" class="main-font-color" @click="cancelSearch('list_search_item')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>
    <div id="list" class="main-list">
      <Table :columns="list.columns" :data="list.data">
        <template slot-scope="{ row }" slot="main_no">
          <strong @click="shippingOut(row)" class="pointer">{{ row.main_no }}</strong>
        </template>

        <template slot-scope="{ row }" slot="status">
          <span :style="getStatusColor(row.status)">{{ getStatusText(row.status) }}</span>
        </template>

        <template slot-scope="{ row }" slot="sale_no">
          <span class="pointer data-copy" @click="copyData(row.sale_no)">{{ row.sale_no }}<Icon type="ios-copy-outline"/></span>
        </template>

        <template slot-scope="{ row }" slot="out_no">
          <span class="pointer data-copy" @click="copyData(row.out_no)">{{ row.out_no }}<Icon type="ios-copy-outline"/></span>
        </template>

        <template slot-scope="{ row, index }" slot="action">
          <Button v-show="row.status==1" type="text" class="fs-12" size="small" v-if="aCheck.rightCheck('operate_ship_out')"
                  :style="getStatusColor(row.status)" @click="shippingOut(row)">
            <Icon type="ios-checkmark-circle"/>
            发货
          </Button>
          <Button v-show="row.status==2" type="text" class="fs-12" size="small" v-if="aCheck.rightCheck('operate_ship_out')"
                  :style="getStatusColor(row.status)" @click="shippingOut(row)">
            <Icon type="ios-checkmark-circle"/>
            完成
          </Button>
          <Button v-show="row.status==3" type="text" class="fs-12" size="small" :style="getStatusColor(row.status)" @click="shippingOut(row)">
            <Icon type="ios-checkmark-circle"/>
            查看
          </Button>
        </template>
      </Table>
      <div class="page">
        <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"
              :current="list.page" show-sizer @on-page-size-change="pageSizeChange" :page-size-opts="list.page_size_opts"/>
      </div>
    </div>

    <!--查看-->
    <Modal v-model="view.modal" :title="view.modal_title" width="960">
      <div class="modalForm">
        <Form :label-width="100" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="发货单号">
                <span>{{ view.data.main_no }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="发货状态">
                <span :style="getStatusColor(view.data.status)">{{ getStatusText(view.data.status) }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="关联销售单">
                <span class="pointer data-copy" @click="copyData(view.data.sale_no)">{{ view.data.sale_no }}<Icon type="ios-copy-outline"/></span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="关联出库单">
                <span class="pointer data-copy" @click="copyData(view.data.out_no)">{{ view.data.out_no }}<Icon type="ios-copy-outline"/></span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="操作者">
                <span>{{ view.data.operator_name }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="客户">
                <span>{{ view.data.client_name }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="客户地址">
                <span>{{ view.data.address }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20" v-if="[2,3].includes(view.data.status)">
            <Col span="12">
              <FormItem label="发货日期">
                <span v-if="[3].includes(view.data.status)">{{view.data.out_date}}</span>
                <DatePicker :value="view.data.out_date" type="datetime"
                            v-if="[2].includes(view.data.status)"
                            format="yyyy-MM-dd HH:mm:ss"
                            placeholder="请填写发货时间(不填，默认当前时间)" style="width: 355px"
                            @on-change="getDateTime"></DatePicker>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="备注">
                <span v-if="[3].includes(view.data.status)">{{view.data.remark}}</span>
                <Input v-if="[2].includes(view.data.status)" v-model="view.data.remark" placeholder="请填写备注信息" maxlength="600"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row class="display-block padding-bottom-10" v-if="[2,3].includes(view.data.status)">
            <Card dis-hover >
              <Button v-if="[2].includes(view.data.status) && common.view_flag == 1" type="primary" slot="extra" size="small" @click="addLogistics(view.data.id)" class="f12">
                <Icon type="md-add"/>
                添加物流
              </Button>
              <div>
                <span style="margin:0 10px" :class="common.view_flag == 1 ? 'select-flag main-font-color pointer': 'pointer'" @click="selectTap(1)">物流明细</span>
                <span style="margin:0 10px" :class="common.view_flag == 2 ? 'select-flag main-font-color pointer': 'pointer'" @click="selectTap(2)">出库明细</span>
                <Divider/>

                <div v-show="common.view_flag == 1">
                  <Table :columns="view.logistics.columns" :data="view.data.logistics_set" style="overflow: visible;">
                    <template slot-scope="{ row }" slot="logistics_name">
                      <span v-for="item in common.logistics_all" v-if="item.id == row.logistics_id">{{item.client_name}}</span>
                    </template>

                    <template slot-scope="{ key,row }" slot="logistics_number">
                      <span v-if="row.logistics_number" class="pointer data-copy" @click="copyData(row.logistics_number)">{{row.logistics_number}}<Icon type="ios-copy-outline"/></span>
                    </template>

                    <template slot-scope="{ key,row }" slot="logistics_pay">
                      <strong class="main-font-color">{{parseFloat(row.logistics_pay)}}</strong>
                    </template>

                    <template slot-scope="{ row }" slot="certificate">
                      <img v-for="(item, key) in row.certificate" :src="item.url+thumbnailUrl" alt="" class="pointer padding-4 preview-img" @click="imgShow(key, row.certificate)">
                    </template>

                    <template slot-scope="{ row }" slot="action">
                      <span v-if="[3].includes(view.data.status)" class="disabled-color">------</span>
                      <Button type="text" size="small" class="button-in-list-normal fs12" @click="editLogisticsDetail(row)" v-if="[2].includes(view.data.status)">
                        <Icon type="ios-create"/>
                        修改
                      </Button>

                      <Button type="text" size="small" class="button-in-list-normal fs12" @click="deleteLogisticsDetail(row)" v-if="[2].includes(view.data.status)">
                        <Icon type="ios-trash"/>
                        删除
                      </Button>
                    </template>
                  </Table>
                </div>

                <div v-show="common.view_flag == 2">
                  <Table  :columns="view.goods.columns" :data="view.data.goods_setted" style="overflow: visible;">
                    <template slot-scope="{ row }" slot="thumbnail_url">
                      <p><img v-show="row.thumbnail_url" class="preview-img" :src="row.thumbnail_url" alt=""></p>
                    </template>

                    <template slot-scope="{ row }" slot="house_info">
                      <Breadcrumb separator=">">
                        <BreadcrumbItem>{{ row.position_Info.house_name }}</BreadcrumbItem>
                        <BreadcrumbItem>{{ row.position_Info.area_name }}</BreadcrumbItem>
                        <BreadcrumbItem>{{ row.position_Info.position_name }}</BreadcrumbItem>
                      </Breadcrumb>
                    </template>
                  </Table>
                </div>
              </div>
            </Card>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleResetModal(view)">关闭</Button>
        <Button type="primary" v-show="view.data.status===1" :loading="view.modal_loading"
                @click="startShipOut(view.data.id)">开始发货
        </Button>
        <Button type="primary" v-show="view.data.status===2" :loading="view.modal_loading"
                @click="finisShipOut">发货完成
        </Button>
      </div>
    </Modal>

    <Modal v-model="add_logistics.modal" :title="add_logistics.modal_title" width="800">
      <div id="modalForm">
        <Form ref="AddLogisticsModal" :model="add_logistics.item" :label-width="100" :rules="add_logistics.ruleInline"
              label-colon>
          <Row :gutter="20">
            <Col span="16" offset="4">
              <FormItem label="物流运营商" prop="logistics_id">
                <Select v-model="add_logistics.item.logistics_id" placeholder="请选择物流商" filterable>
                  <Option v-for="item in common.logistics_all" :value="item.id" :key="item.id">{{item.client_name}}</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="16" offset="4">
              <FormItem label="物流单号" prop="logistics_number">
                <Input v-model="add_logistics.item.logistics_number" placeholder="请填写物流单号" maxlength="200"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="16" offset="4">
              <FormItem label="物流费用" prop="logistics_pay">
                <Input v-model="add_logistics.item.logistics_pay" placeholder="请填写物流费用" type="number"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="16" offset="4">
              <FormItem label="凭证" prop="certificate">
                <!-- fileList为回传属性    uploadParam用来传值                -->
                <ImageUpload ref="image_upload" @fileList="getLogisticsImgList" :uploadParam="add_logistics.uploadParam"
                             :key="add_logistics.uploadParam.key"/>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="cancelLogisticsModal('AddLogisticsModal')">关闭</Button>
        <Button type="primary" :loading="add_logistics.modal_loading" @click="createLogistics('AddLogisticsModal')">保存
        </Button>
      </div>
    </Modal>

    <ImagePreview ref="image_preview"/>
  </div>
</template>

<script>
import {
  getShipOutList,
  getOutInfoForShip,
  startShipOut,
  shipOutBindLogistics,
  apiGetShipOutLogisticsInfo,
  apiDeleteShipBindLogisticsDetail,
  apiFinisShipOut
} from '@/api/goods/shipOut';
import * as logisticsUtil from '@/utils/logistics';
import ImageUpload from '@/components/common/ImageUpload.vue';
import ImagePreview from '@/components/common/Image/ImagePreview';

export default {
  name: "ShipOut",
  data() {
    return {
      thumbnailUrl: '?x-oss-process=image/resize,m_mfit,h_100,w_100',
      view: {
        modal: false,
        modal_title: '发货信息',
        data: {
          main_no: '',
          address: '',
          logistics_set: [],
          remark: '',
          out_date: '',
          operator_name:'',
        },
        logistics: {
          columns: [
            {
              title: '物流名称',
              key: 'logistics_name',
              slot: 'logistics_name',
              align: 'center'
            },
            {
              title: '物流单号',
              key: 'logistics_number',
              slot: 'logistics_number',
              align: 'center'
            },
            {
              title: '凭证',
              key: 'certificate',
              slot: 'certificate',
              align: 'center'
            },
            {
              title: '物流费用',
              key: 'logistics_pay',
              slot: 'logistics_pay',
              align: 'center'
            },
            {
              title: '操作',
              key: 'action',
              align: 'center',
              slot: 'action',
            }
          ],
        },
        goods: {
          columns: [
            {
              title: '货品编号',
              key: 'part_no',
              align: 'center'
            },
            {
              title: '货品名称',
              key: 'part_name',
              align: 'center'
            },
            {
              title: '缩略图',
              key: 'thumbnail_url',
              slot: 'thumbnail_url',
              align: 'center'
            },
            {
              title: '已出库数量',
              key: 'num',
              align: 'center'
            },
            {
              title: '来源仓库位置',
              key: 'house_info',
              slot: 'house_info',
              width: 240,
              align: 'center'
            },
          ],
        }
      },
      add_logistics: {
        modal: false,
        modal_loading: false,
        modal_title: '物流信息',
        item: {
          logistics_id: '',
          logistics_number: '',
          logistics_pay: '',
          certificate: [],
        },
        ruleInline: {
          logistics_id: [
            {required: true, message: '请选择物流商'}
          ],
        },
        uploadParam: {
          list_length: 2,
          upload_type: 2,//2表示凭证上传
          module_type: 5,//5表示发货文件夹
          prefix: '',
          list: [],
          key: 0,
        }
      },
      list: {
        page_size_key: 'ship_out_page_size_key',
        page_size_opts: [10, 15, 20, 30],
        search_item: {
          main_no: '',
          sale_no: '',
          out_no: '',
          created_at: []
        },
        columns: [
          {
            title: '发货单号',
            key: 'main_no',
            slot: 'main_no',
            align: 'center',
            width: 210,
          },
          {
            title: '发货状态',
            key: 'status',
            slot: 'status',
            align: 'center',
          },
          {
            title: '客户',
            key: 'client_name',
            align: 'center',
          },
          {
            title: '发货日期',
            key: 'out_date',
            align: 'center',
          },
          {
            title: '关联销售单',
            key: 'sale_no',
            slot: 'sale_no',
            align: 'center',
          },
          {
            title: '关联出库单',
            key: 'out_no',
            slot: 'out_no',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
            width: 160,
          }
        ],
        data: [],
        size: 10,
      },
      common: {
        view_flag: 1,
        logistics_all: [],
        img_preview_data:{},
      }
    }

  },
  methods: {
    selectTap(flag) {
      this.common.view_flag = flag;
    },
    imgShow(value, img_info) {
      this.$refs.image_preview.imgPreview(value, img_info);
    },
    copyData(logistics_number) {
      let clipboard = new this.clipboard('.data-copy', {
        text: function() {
          return logistics_number;
        }
      });
      clipboard.on('success', () => {
        this.$Message.success('复制成功！');
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        this.$Message.error('复制失败，请手动选择复制！');
        clipboard.destroy();
      });
    },
    getDateTime(value, type) {
      this.view.data.out_date = value;
    },
    editLogisticsDetail(row) {
      this.add_logistics.item = row;
      this.setUploadParamPrefix(row.ship_id);
      this.add_logistics.uploadParam.list = row.certificate;
      this.add_logistics.modal_loading = false;
      this.add_logistics.modal = true;
    },
    deleteLogisticsDetail(row) {
      this.$Modal.confirm({
        title: "删除物流信息",
        content: '确定删除该物流信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          let param = {
            id: row.id,
            ship_id: row.ship_id,
          }
          apiDeleteShipBindLogisticsDetail(param).then(res => {
            if (res.data.err_no === 0) {
              this.$Message.success('物流信息删除成功');
              this.view.data.logistics_set = res.data.results
              // 强制重新进行双向绑定
              this.$forceUpdate();
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    getLogisticsImgList(val) {
      this.add_logistics.item.certificate = [];
      for (let i = 0; i < val.length; i++) {
        this.add_logistics.item.certificate[i] = {};
        this.add_logistics.item.certificate[i].url = val[i].url;
      }
    },
    cancelLogisticsModal(item) {
      this.$refs[item].resetFields();
      this.add_logistics.modal = false;
      this.add_logistics.modal_loading = false;
    },
    //绑定物流数据
    createLogistics(name) {
      this.add_logistics.modal_loading = true;

      this.$refs[name].validate((valid) => {
        if (valid) {
          if (!this.add_logistics.item.certificate.length && !this.add_logistics.item.logistics_number) {
            this.$Message.error('物流单号和物流凭证不能全部为空');
            this.add_logistics.modal_loading = false;
            return;
          }
          shipOutBindLogistics(this.add_logistics.item).then(res => {
            if (res.data.err_no == 0) {
              this.$Message.success('物流信息保存成功');
              this.add_logistics.modal = false;
              this.view.data.logistics_set = res.data.results;
              this.$refs[name].resetFields();
            } else {
              this.$Message.error(res.data.err_msg);
            }
            this.add_logistics.modal_loading = false;
          }).catch((res) => {
            this.$Message.error('操作错误');
            this.add_logistics.modal_loading = false;
          });
        } else {
          this.add_logistics.modal_loading = false;
        }
      });
    },
    addLogistics(ship_id) {
      //添加物流信息
      this.add_logistics.item.ship_id = ship_id;
      this.add_logistics.modal_loading = false;
      this.add_logistics.modal = true;

      this.setUploadParamPrefix(ship_id);
    },
    setUploadParamPrefix(ship_id) {
      this.add_logistics.uploadParam.list = [];
      this.add_logistics.uploadParam.key += 1;
      //增加图片上传前缀
      this.add_logistics.uploadParam.prefix = ship_id.toString() + '-' + (new Date().getTime()).toString();
    },
    startShipOut(id) {
      let param = {
        ship_id: id,
        main_no: this.view.data.main_no,
      };
      startShipOut(param).then((res) => {
        if (res.data.err_no === 0) {
          this.$Message.success('操作成功');
          this.view.data.status = 2;//开始发货
        }
      });
    },
    finisShipOut() { //发货完成
      let param = {
        ship_id: this.view.data.id,
        remark: this.view.data.remark,
        out_date: this.view.data.out_date,
        main_no: this.view.data.main_no,
        sale_id: this.view.data.sale_id,
      };
      apiFinisShipOut(param).then((res) => {
        if (res.data.err_no === 0) {
          this.$Message.success('操作成功');
          this.view.data.status = 3;//开始发货
        }
      });
    },
    handleResetModal() {
      this.view.modal = false;
      this.view.modal_loading = false;
    },
    getPayTypeColor(index) {
      //获取不同配色
      return index == 2 ? 'color:#ff9900' : '';
    },
    getShipOutLogisticsInfo(id) {
      let param = {
        ship_id: id,
      };
      apiGetShipOutLogisticsInfo(param).then(res => {
        this.view.data.logistics_set = [];
        this.view.data.logistics_set = res.data.results;
      });
    },
    shippingOut(row) {
      this.view.data = row;
      this.view.data.out_goods = [];
      this.view.modal_loading = false;

      this.getShipOutLogisticsInfo(row.id);

      //获取出库信息
      let param = {
        params: {
          out_id: row.house_out_id,
        }
      };
      getOutInfoForShip(param).then((res) => {
        for (let i = 0; i < res.data.results.outInfo.outGoods.length; i++) {
          res.data.results.outInfo.outGoods[i].num = parseFloat(res.data.results.outInfo.outGoods[i].num);
        }
        this.view.data.goods_setted = res.data.results.outInfo.outGoods;
        this.view.data.address = res.data.results.saleInfo.address;
        this.view.modal = true;
      });
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.list.page = 1;
      this.getMainList();
    },
    timeChange(dateRange) {
      if (dateRange[0] && dateRange[1]) {
        this.list.search_item.created_at = dateRange;
      } else {
        this.list.search_item.created_at = "";
      }

      this.searchSubmit();
    },
    getStatusText(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = '待发货';
          break;
        case 2:
          rs = '发货中';
          break;
        case 3:
          rs = '已发货';
          break;
        case 4:
          rs = '已收货';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getStatusColor(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = 'color:#ff9900';
          break;
        case 2:
          rs = 'color:#2775ff';
          break;
        case 3:
          rs = 'color:#19be6b';
          break;
        case 4:
          rs = 'color:#2775ff';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    searchSubmit() {
      this.list.page = 1;
      this.getMainList();
    },
    getMainList() {
      let param = {
        page: this.list.page,
        main_no: this.list.search_item.main_no,
        sale_no: this.list.search_item.sale_no,
        out_no: this.list.search_item.out_no,
        created_at: this.list.search_item.created_at,
        size: this.list.size,
      };

      getShipOutList(param).then((res) => {
        this.list.data = res.data.results.list;
        this.list.total = res.data.results.total;
        this.list.size = res.data.results.size;
      });
    },
    pageSizeChange(page_size) {
      this.list.size = page_size;
      localStorage.setItem(this.list.page_size_key, page_size);
      this.getMainList();
    },
    changePage(page) {
      this.list.page = page;
      this.getMainList();
    },
    setSelectedPageSize() {
      let size = localStorage.getItem(this.list.page_size_key);
      this.list.size = size ? (size > 50 ? 40 : parseInt(size)) : 0;
    },
  },
  mounted() {
    this.setSelectedPageSize();

    this.getMainList();

    logisticsUtil.getLogisticsAll().then(res => {
      this.common.logistics_all = res;
    });
  },
  components: {
    ImageUpload,
    ImagePreview
  },
  created() {
    this.aCheck.plateName = 'product_manage';
  },
}
</script>

<style scoped>
.select-flag {
  font-size: 16px;
  font-weight: 500;
}
</style>
<style>
#ship-container .ivu-card {
  background-color: #fafafa;
}

#ship-container .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#ship-container .ivu-form-item {
  margin-bottom: 0;
}

#list .page {
  margin-top: 16px;
}

.main-list .ivu-table-overflowX, .ivu-table-tip {
  overflow-x: hidden;
}

.main-list .ivu-table-wrapper {
  overflow: visible;
}
</style>
