<template>
  <div id="goods-category" class="module-body">
    <div id="panel-header" class="panel-header">
      <span class="top-title">货品品类</span>
      <Button type="primary" class="client_create" @click="create('add_edit.item')">添加品类</Button>
    </div>

    <div id="filter-bar" class="filter-bar">
      <Card dis-hover>
        <Form ref="list_search_item" :model="list.search_item" inline >
          <FormItem prop="main_no">
            <Input @on-blur="searchSubmit()" v-model="list.search_item.main_no" placeholder="请填写品类号" class="w-200"/>
          </FormItem>

          <FormItem  prop="name">
            <Input @on-blur="searchSubmit()" v-model="list.search_item.name" placeholder="请填写品类名称" class="w-200"/>
          </FormItem>

          <FormItem  prop="type">
            <Select @on-change="searchSubmit()" v-model="list.search_item.type" placeholder="请选择品类类型" class="w-200">
              <Option v-for="item in common.all_type" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>

          <FormItem>&nbsp;
            <Button type="text" class="main-font-color" @click="cancelSearch('list_search_item')">
              清除
            </Button>
          </FormItem>
        </Form>
      </Card>
    </div>

    <div id="list" class="main-list">
      <Table :loading="common.list_loading" :columns="list.columns" :data="list.data">
        <template slot-scope="{ row }" slot="main_no">
          <strong @click="viewGoodsCategory(row)" class="pointer">{{ row.main_no }}</strong>
        </template>

        <template slot-scope="{ row }" slot="status">
          <span :style="getStatusColor(row.status)">{{ getStatusText(row.status) }}</span>
        </template>

        <template slot-scope="{ row }" slot="type">
          <span v-for="item in common.goods_type" v-show="item.id==row.type">{{ item.name }}</span>
        </template>

        <template slot-scope="{ row }" slot="link_goods_num">
          <span>{{ row.link_goods_num }}</span><span class="main-font-color pointer" @click="refreshLinkGoodsNum(row)"><Icon type="ios-refresh" /></span>
        </template>

        <template slot-scope="{ row, index }" slot="action">
          <Button v-show="[0].includes(row.status)" type="text" size="small" @click="validGoodsCategory(row)" class="danger-color fs-12">
            <Icon type="ios-checkmark-circle"/>
            生效
          </Button>

          <Button v-show="row.status!=0" type="text" size="small" @click="viewGoodsCategory(row)" class="success-color fs-12">
            <Icon type="ios-checkmark-circle"/>
            查看
          </Button>

          <Button v-show="[1].includes(row.status)" type="text" size="small" @click="invalidGoodsCategory(row)" class="warning-color fs-12">
            <Icon type="ios-checkmark-circle"/>
            失效
          </Button>

          <Dropdown v-show="[0].includes(row.status)">
            <Button size="small" type="text" class="f12">
              更多
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem v-show="[0].includes(row.status)" >
                <span @click="listEdit(row)" class="f12">修改</span>
              </DropdownItem>
              <DropdownItem v-show="[0].includes(row.status)" >
                <span @click="listDeleteAlert(row)" class="f12">删除</span>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>

        </template>
      </Table>
      <div class="page">
        <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"
              :current="list.page" show-sizer @on-page-size-change="pageSizeChange" :page-size-opts="list.page_size_opts"/>
      </div>
    </div>

    <!--添加-->
    <Modal v-model="add_edit.modal" :title="add_edit.modal_title" width="820">
      <div class="modalForm">
        <Form v-show="!common.view_loading" ref="add_edit.item" :model="add_edit.item" :label-width="100" :rules="add_edit.ruleInline" label-colon>
          <Row type="flex" justify="center">
            <Col span="12">
              <FormItem label="品类号" prop="main_no">
                <Input v-model="add_edit.item.main_no" disabled placeholder="请填写品类号"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col span="12">
              <FormItem label="品类名称" prop="name">
                <Input v-model="add_edit.item.name" maxlength="200" placeholder="请填写品类名称"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col span="12">
              <FormItem label="品类类型" prop="type">
                <Select v-model="add_edit.item.type" placeholder="请选择货品类型">
                  <Option value="0" key="0">暂无</Option>
                  <Option v-for="item in common.goods_type" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
        </Form>

        <Spin fix v-show="common.view_loading" size="large"></Spin>
      </div>
      <div slot="footer">
        <Button @click="addEditReset('add_edit.item')">关闭</Button>
        <Button type="primary" :loading="add_edit.modal_loading" @click="createSave('add_edit.item')">保存</Button>
      </div>
    </Modal>

    <!--查看-->
    <Modal v-model="view.modal" :title="view.modal_title" width="820">
      <div class="modalForm">
        <Form  v-show="!common.view_loading" :model="view.data" :label-width="100" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="品类号" prop="main_no">
                <span>{{ view.data.main_no }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="品类名称" prop="name">
                <span>{{ view.data.name }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="类型" prop="type">
                <span v-for="item in common.goods_type" v-show="item.id==view.data.type">{{ item.name }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="关联货品数" prop="link_goods_num">
                <span>{{ view.data.link_goods_num }}</span><span class="main-font-color pointer" @click="refreshLinkGoodsNum(view.data)"><Icon type="ios-refresh" /></span>
              </FormItem>
            </Col>
          </Row>

          <Row class="display-block">
            <Card :bordered="false">
              <p slot="title">关联货品</p>
              <Button type="success" :loading="view.batch_modal_loading" slot="extra" size="small" @click="saveBatch(view.data)" class="f12">
                <Icon type="md-checkmark" />
                一键保存
              </Button>
              <Button type="primary" slot="extra" size="small" @click="addGoods()" class="f12 ml-10">
                <Icon type="md-add"/>
                关联货品
              </Button>
              <Table :columns="view.goods_columns" :data="view.data.goods_set" >
                <template slot-scope="{ row }" slot="thumbnail_url">
                  <p><img v-show="row.thumbnail_url" class="preview-img" :src="row.thumbnail_url" alt=""></p>
                </template>
                <template slot-scope="{ row }" slot="stock_all">
                  <span>{{ minus(row.stock_all, row.frozen_stock) }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="sale_price">
                  <span>{{ row.sale_price }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                  <Button type="text" :loading="row.modal_loading" size="small" v-show="row.add_flag"
                          class="button-in-text-blue fs-12" @click="bindCategoryGoods(index, row, view.data.id)">
                    <Icon type="ios-archive"/>
                    保存
                  </Button>
                  <Button type="text" size="small" v-show="row.add_flag" class="button-in-list-normal fs-12" @click="simpleUntieCategoryGoods(index, row)">
                    <Icon type="ios-trash"/>
                    删除
                  </Button>
                  <Button type="text" size="small" v-show="!row.add_flag" class="button-in-list-normal fs-12" @click="untieCategoryGoods(index, row, view.data.id)">
                    <Icon type="ios-trash"/>
                    解绑
                  </Button>
                </template>
              </Table>
            </Card>
          </Row>

        </Form>
        <Spin fix v-show="common.view_loading" size="large"></Spin>
      </div>
      <div slot="footer">
        <Button @click="closeViewModal">关闭</Button>
      </div>
    </Modal>

    <SelectGoods ref="select_goods" @selected="getSelectedGoods" :select_goods="common.select_goods"/>
  </div>
</template>

<script>
import SelectGoods from '@/components/common/SelectGoods.vue';

import {
  getGoodsCategoryList,
  getGoodsCategoryMainNo,
  saveGoodsCategory,
  deleteGoodsCategory,
  validGoodsCategory,
  invalidGoodsCategory,
  getCategoryGoods,
  bindCategoryGoods,
  untieCategoryGoods,
  refreshLinkGoodsNum
} from '@/api/goods/goodsCategory';
import NP from "number-precision";

export default {
  name: "GoodsCategory",
  data() {
    return {
      common: {
        list_loading: false,
        view_loading: false,
        goods_type: [
          {
            id: 2,
            name: '成品',
          },
          {
            id: 1,
            name: '物料',
          },
          {
            id: 3,
            name: '半成品',
          },
        ],
        all_type: [
          {
            id: 2,
            name: '成品',
          },
          {
            id: 1,
            name: '物料',
          },
          {
            id: 3,
            name: '半成品',
          },

        ],
        select_goods: {
          modal: false,
          count_flag: 0,
          goods_type: 0,
          client_id: 0,
        },
      },

      list: {
        page_size_opts: [10, 15, 20, 30],
        page_size_key: 'goods_category_page_size_key',
        search_item: {
          main_no: '',
          name: '',
          type: '',
        },
        columns: [
          {
            title: '品类号',
            key: 'main_no',
            slot: 'main_no',
            align: 'center',
            width: 210,
          },
          {
            title: '品类名称',
            key: 'name',
            align: 'center',
          },
          {
            title: '状态',
            key: 'status',
            slot: 'status',
            align: 'center',
          },
          {
            title: '类型',
            key: 'type',
            slot: 'type',
            align: 'center',
          },
          {
            title: '关联货品数',
            key: 'link_goods_num',
            slot: 'link_goods_num',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
            width: 160,
          }
        ],
        data: [],
        size:0,
      },

      add_edit: {
        modal: false,
        modal_title: '编辑货品品类',
        modal_loading: false,
        item: {
          id: 0,
          main_no: '',
          name: '',
          type: '',
          link_goods_num: 0,
        },
        ruleInline: {
          name: [
            {required: true, message: '请填写品类名称'}
          ],
        }
      },

      view: {
        batch_modal_loading: false,
        modal: false,
        modal_title: '查看货品分类信息',
        data: {
          id: '',
          main_no: '',
          name: '',
          type: '',
          status: '',
          goods_set: [],
          link_goods_num: 0,
        },
        goods_columns: [
          {
            title: '货品编号',
            key: 'part_no',
            align: 'center',
          },
          {
            title: '货品名称',
            key: 'part_name',
            align: 'center',
          },
          {
            title: '缩略图',
            key: 'thumbnail_url',
            slot: 'thumbnail_url',
            align: 'center',
          },
          {
            title: '可用库存',
            slot: 'stock_all',
            key: 'stock_all',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            slot: 'action',
            align: 'center',
          }
        ],
      }
    }
  },
  methods: {
    minus(a, b) {
      return NP.minus(a, b)
    },
    refreshLinkGoodsNum(row) {
      refreshLinkGoodsNum({category_id:row.id}).then(response => {
        row.link_goods_num = response.data.results;
        this.$Message.success('刷新成功！');
      });
    },
    searchSubmit() {
      this.common.page = 1;
      this.getMainList();
    },
    closeViewModal() {
      this.view.modal = false;
    },
    simpleUntieCategoryGoods() {
      this.view.data.goods_set.splice(index, 1);
    },
    untieCategoryGoods(index, row, category_id) {
      this.$Modal.confirm({
        title: "解绑货品与品类关系",
        content: '确定解绑货品与品类关系？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          let param = {
            category_id: category_id,
            goods_id: row.id,
          };
          untieCategoryGoods(param).then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.view.data.goods_set.splice(index, 1);
            } else {
              this.$Message.error(response.data.err_msg);
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    bindCategoryGoods(index, row, category_id) {
      row.modal_loading = true;

      //判断现有库存是否已填写
      let param = {
        category_id: category_id,
        goods_id: [row.id],
      };

      bindCategoryGoods(param).then((response) => {
        if (response.data.err_no == 0) {
          row.add_flag = 0;
          this.$Message.success(response.data.results);
          this.view.data.goods_set[index] = row;
        } else {
          this.$Message.error(response.data.err_msg);
        }
      });
    },
    saveBatch(row) {
        this.view.batch_modal_loading = true;
        let goods_ids = [];
        for (let i=0; i<row.goods_set.length; i++) {
          if (row.goods_set[i].add_flag == 1) {
            goods_ids.push(row.goods_set[i].goods_id)
          }
        }

        if (goods_ids.length <= 0) {
          this.$Message.error("暂没有需要关联的货品！");
          this.view.batch_modal_loading = false;
          return;
        }

      //判断现有库存是否已填写
      let param = {
        category_id: row.id,
        goods_id: goods_ids,
      };

      bindCategoryGoods(param).then((res) => {
        if (res.data.err_no == 0) {
          this.viewGoodsCategory(row);
          this.$Message.success(res.data.results);
        } else {
          this.$Message.error(res.data.err_msg);
        }

        this.view.batch_modal_loading = false;
      }).catch(error => {
        this.$Message.error("操作失败！");
        this.view.batch_modal_loading = false;
      });
    },
    getSelectedGoods(val) {
      //获取选中的货品
      let selected_goods = val;
      let goods_set = this.view.data.goods_set;

      if (selected_goods.length) {
        for (let i = 0, len = selected_goods.length; i < len; i++) {
          let added_flag = false;

          //排除已添加的
          for (let j = 0, jLen = goods_set.length; j < jLen; j++) {
            if (goods_set[j].id == selected_goods[i].id) {
              added_flag = true;
              break;
            }
          }

          //如果未添加
          if (!added_flag) {
            selected_goods[i].add_flag = 1;
            goods_set.push(selected_goods[i]);
          }
        }
      }
    },
    //添加货品
    addGoods() {
      this.$refs.select_goods.select_goods.goods_type = this.common.select_goods.goods_type;
      this.$refs.select_goods.showGoodsList();
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.list.page = 1;
      this.getMainList();
    },
    viewGoodsCategory(row) {
      this.common.view_loading = true;
      getCategoryGoods({category_id: row.id}).then(response => {
        this.common.view_loading = false;
        this.view.data.id = row.id;
        this.view.data.main_no = row.main_no;
        this.view.data.name = row.name;
        this.view.data.type = row.type;
        this.view.data.status = row.status;
        this.view.data.link_goods_num = row.link_goods_num;
        this.view.data.goods_set = response.data.results;

        this.view.modal = true;
      });
    },
    getStatusText(index) {
      let rs = '';
      switch (index) {
        case 0:
          rs = '待生效';
          break;
        case 1:
          rs = '已生效';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getStatusColor(index) {
      let rs = '';
      switch (index) {
        case 0:
          rs = 'color:#ed4014';
          break;
        case 1:
          rs = 'color:#19be6b';
          break;

        default:
          rs = '';
          break;
      }

      return rs;
    },
    getMainList() {
      this.common.list_loading = true;
      let param = {
        page: this.list.page,
        main_no: this.list.search_item.main_no,
        name: this.list.search_item.name,
        type: this.list.search_item.type,
        size: this.list.size
      };

      getGoodsCategoryList(param).then((res) => {
        this.common.list_loading = false;
        this.list.data = res.data.results.list;
        this.list.total = res.data.results.total;
        this.list.size = res.data.results.size;
      });
    },

    validGoodsCategory(row) {
      validGoodsCategory({id:row.id}).then((response) => {
        if (response.data.err_no == 0) {
          this.$Message.success(response.data.results);
          this.getMainList();
        } else {
          this.$Message.error(response.data.err_msg);
        }
      });
    },

    invalidGoodsCategory(row) {
      invalidGoodsCategory({id:row.id}).then((response) => {
        if (response.data.err_no == 0) {
          this.$Message.success(response.data.results);
          this.getMainList();
        } else {
          this.$Message.error(response.data.err_msg);
        }
      });
    },

    create(name) {
      this.$refs[name].resetFields();

      this.add_edit.item.id = 0;
      getGoodsCategoryMainNo().then((response) => {
        if (response.data.err_no == 0) {
          this.add_edit.item.main_no = response.data.results;
        }
      });

      this.add_edit.modal = true;
    },

    listEdit(row) {
      this.add_edit.item.id = row.id;
      this.add_edit.item.main_no = row.main_no;
      this.add_edit.item.name = row.name;
      this.add_edit.item.type = row.type;
      this.add_edit.item.link_goods_num = row.link_goods_num;
      this.add_edit.modal = true;
    },

    listDeleteAlert(row) {
      if (parseInt(row.link_goods_num) > 0) {
        this.$Message.error('删除货品品类前，请解绑相关货品');
        return;
      }

      this.$Modal.confirm({
        title: "删除货品品类信息",
        content: '确定删除删除货品品类信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.deleteListInfo(row.id);
        },
        onCancel: () => {
        }
      });
    },

    deleteListInfo($id) {
      deleteGoodsCategory({id:$id}).then(response => {
        if (response.data.err_no == 0) {
          this.$Message.success(response.data.results);
          this.getMainList();
        } else {
          this.$Message.error(response.data.err_msg);
        }
      });
    },

    addEditReset(name) {
      this.add_edit.modal = false;
      this.add_edit.modal_loading = false;
    },
    createSave(name) {
      this.add_edit.modal_loading = true;

      this.$refs[name].validate((valid) => {
        if (!valid) {
          this.add_edit.modal_loading = false;
        } else {
          saveGoodsCategory(this.add_edit.item).then(response => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.add_edit.modal  = false;
              this.getMainList();
            } else {
              this.$Message.error(response.data.err_msg);
            }
          }).catch(error => {
            this.add_edit.modal_loading = false;
            this.$Message.error('保存失败！');
          });
        }
      });
    },
    changePage(page) {
      this.list.page = page;
      this.getMainList();
    },
    pageSizeChange(page_size) {
      this.list.size = page_size;
      localStorage.setItem(this.list.page_size_key, page_size);
      this.getMainList();
    },
    setSelectedPageSize() {
      let size = localStorage.getItem(this.list.page_size_key);
      this.list.size = size ? (size > 50 ? 40 : parseInt(size)) : 0;
    },
  },
  mounted() {
    this.setSelectedPageSize();
    this.getMainList();
  },
  components: {
    SelectGoods,
  }
}
</script>

<style scoped>
.client_create {
  position: absolute;
  right: 0;
  top: -8px;
}
</style>
<style>
#goods-category .ivu-card {
  background-color: #fafafa;
}

#goods-category .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#goods-category .ivu-form-item {
  margin-bottom: 0;
}

#list .page {
  margin-top: 16px;
}

.main-list .ivu-table-overflowX, .ivu-table-tip {
  overflow-x: hidden;
}

.main-list .ivu-table-wrapper {
  overflow: visible;
}
</style>
