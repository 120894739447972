<template>
  <div id="warehouse-info" class="module-body">
    <div class="panel-header">
      <span class="top-title">仓库概况</span>
    </div>

    <div id="filter-bar">
      <Card dis-hover>
        <Form :model="formItem" inline ref="formItem">
          <FormItem prop="part_no">
            <Input @on-blur="searchSubmit()" v-model="formItem.part_no" placeholder="请填写货品编号" class="w-200"/>
          </FormItem>

          <FormItem prop="part_name">
            <Input @on-blur="searchSubmit()" v-model="formItem.part_name" placeholder="请填写货品名称" class="w-200"/>
          </FormItem>

          <FormItem prop="goods_type">
            <Select @on-change="searchSubmit()" v-model="formItem.goods_type" placeholder="请选择货品类型" filterable class="w-200">
              <Option v-for="item in common.goods_type" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>

          <FormItem prop="category_id">
            <Select @on-change="searchSubmit()" v-model="formItem.category_id" class="w-200" filterable  placeholder="请选择货品品类">
              <Option v-for="(option, index) in common.category_options" :value="option.value" :key="index">{{option.label}}</Option>
            </Select>
          </FormItem>

          <FormItem prop="bom_label">
            <Select @on-change="searchSubmit()" v-model="formItem.bom_label" class="w-200"  placeholder="是否关联bom部件">
              <Option v-for="(option, index) in common.bom_select" :value="option.id" :key="index">{{option.name}}</Option>
            </Select>
          </FormItem>

          <FormItem style="margin-right: 0;">&nbsp;
            <Button type="text" class="main-font-color" @click="cancelSearch('formItem')">
              清除
            </Button>
          </FormItem>
        </Form>
      </Card>
    </div>

    <div>
      <div v-for="item in houseInfo">
        <Divider orientation="left">
          <span class="main-font-color">{{ item.house_name }}</span>
          <span class="fs-12 margin-left-10">( 库区数：<span class="main-font-color">{{item.area_length}}</span>， 库位数：<span class="main-font-color">{{item.position_length}}</span> )</span>
        </Divider>
        <div class="area-info">
          <div v-for="area in item.area" :data-content="area.area_name" class="position-info">
            <div class="position-info-main">
              <div v-for="position in area.position" :class="position.stock.length>0 ? 'position-detail' : 'position-detail-empty'"
                   @click="viewStockInfo(position.stock, position.position_name)">
                <span>{{position.position_name}} ({{position.stock.length}})</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal v-model="viewStock.modal" :title="viewStock.modal_title" width="960">
      <Table :columns="viewStock.columns" :data="viewStock.data"  >
        <template slot-scope="{ row }" slot="goods_type">
          <span>{{ getGoodsType(row.goods_type) }}</span>
        </template>
      </Table>
      <div slot="footer">
        <Button @click="closeViewStockModal">关闭</Button>
      </div>
    </Modal>
  </div>

</template>

<script>
import {getWarehouseInfo} from '@/api/goods/warehouse';
import {getGoodsCategoryList} from "@/api/goods/goodsCategory";

export default {
  name: "WarehouseInfo",
  data() {
    return {
      common: {
        category_options: [],
        bom_select: [
          {
            id: 1,
            name: '关联货品BOM部件',
          },
        ],
        goods_type: [
          {
            id: 1,
            name: '物料',
          },
          {
            id: 2,
            name: '成品',
          },
          {
            id: 3,
            name: '半成品',
          },
          {
            id: 4,
            name: '虚拟',
          },
        ],
      },
      formItem: {
        part_no: '',
        part_name: '',
        goods_type: 0,
        category_id: 0,
        bom_label: 0,
      },
      houseInfo:[],
      viewStock: {
        modal_title:'',
        modal: false,
        data: [],
        columns: [
          {
            title: '货品编号',
            key: 'goods_no',
            align: 'center',
          },
          {
            title: '货品名称',
            key: 'goods_name',
            align: 'center',
          },
          {
            title: '货品类型',
            key: 'goods_type',
            slot: 'goods_type',
            align: 'center',
          },
          {
            title: '货品数量',
            key: 'num',
            align: 'center',
          },
          {
            title: '库存最新变动时间',
            key: 'updated_at',
            align: 'center',
          },
        ]
      }
    }
  },
  methods: {
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.getWarehouseInfo();
    },
    getGoodsType(type) {
      let name = '';
      switch (type) {
        case 1:
          name = '物料';
          break;
        case 2:
          name = '成品';
          break;
        case 3:
          name = '半成品';
          break;
        case 4:
          name = '虚拟';
          break;
      }
      return name;
    },
    goodsCategoryList() {
      getGoodsCategoryList({size: 400}).then(response => {
        this.common.category_options = response.data.results.list.map(item => {
          return {
            value: item.id,
            label: item.name
          };
        });
      });
    },
    searchSubmit() {
      this.getWarehouseInfo();
    },
    getWarehouseInfo() {
      let param = {
        goods_no: this.formItem.part_no,
        goods_name: this.formItem.part_name,
        goods_type: this.formItem.goods_type,
        category_id: this.formItem.category_id,
        bom_label: this.formItem.bom_label,
      }
      getWarehouseInfo(param).then(res => {
        if (res.data.err_no == 0) {
          this.houseInfo = res.data.results;
        } else {
          this.houseInfo = [];
        }
      });
    },
    viewStockInfo(stock, position_name) {
      if (stock.length > 0) {
        this.viewStock.modal_title = position_name+'货品查看';
        for (let i = 0; i < stock.length; i++) {
          stock[i].num = parseFloat(stock[i].num);
        }
        this.viewStock.data = stock;
        this.viewStock.modal = true;
      }
    },
    closeViewStockModal() {
      this.viewStock.modal = false;
    }
  },
  created() {
    this.getWarehouseInfo();
    this.goodsCategoryList();
  }
}
</script>

<style scoped>
#warehouse-info .ivu-card {
  background-color: #fafafa;
}

#warehouse-info .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#warehouse-info .ivu-btn-small {
  font-size: 12px !important;
}

.area-info {
  display: flex;
  flex-wrap: wrap;
}
#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#warehouse-info .ivu-form-item {
  margin-bottom: 0;
}

.position-info {
  width: auto;
  height: 100%;
  margin: 10px 10px;
  position: relative;
  border: 1px solid #e8eaec;
  padding: 10px;
  border-radius: 4px;
}

.position-info-main {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  margin: 0 10px;
}

.position-info::after {
  content: attr(data-content);
  position: absolute;
  top: -9px;
  font-size: 13px;
  font-weight: 500;
  background-color: #fff;
  text-align: center;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: unset;
  letter-spacing: 1px;
  color: #2775ff;
  width: auto;
  padding: 0 3px;
}

.position-detail {
  width: auto;
  height: 56px;
  padding: 0 10px;
  background-color: #2775ff;
  margin: 4px;
  cursor: pointer;
  color: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.position-detail-empty {
  width: auto;
  height: 56px;
  padding: 0 10px;
  background-color: #b1b5b9;
  margin: 4px;
  cursor: pointer;
  color: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
