import {oss} from '@/plugins/oss'
import $axios from '@/plugins/http'
import {getUploadType} from '@/utils/sysUploadType'

export const upload = {
  sys_upload_type: '',//系统默认文件上传方式  目前支持oss、本地服务器
  getUploadType() {
    return getUploadType()
  },
  initOperation(param) {
    this.getUploadType().then(upload_type => {
      this.sys_upload_type = upload_type;

      if (upload_type == 'oss') {
        oss.getSignature(param);
      }
    })
  },
  getSysUploadInfo(fileName = '') {
    if (this.sys_upload_type == 'oss') {
      return oss.ossInfo(fileName)
    }


  }
}
