<template>
  <div id="stock-log" class="module-body">
    <div id="panel-header" class="panel-header">
      <span class="top-title">库存变动</span>
    </div>

    <div id="filter-bar" class="filter-bar">
      <Card dis-hover>
        <Form ref="list_search_item" :model="list.search_item" inline>
          <FormItem prop="part_no">
            <Input @on-blur="searchSubmit" v-model="list.search_item.part_no" placeholder="请填写货品号" class="w-160"/>
          </FormItem>

          <FormItem prop="part_name">
            <Input @on-blur="searchSubmit" v-model="list.search_item.part_name" placeholder="请填写货品名称" class="w-160"/>
          </FormItem>

          <FormItem prop="relation_no">
            <Input @on-blur="searchSubmit" v-model="list.search_item.relation_no" placeholder="请填写关联单号" class="w-160"/>
          </FormItem>

          <FormItem prop="created_at">
            <DatePicker type="daterange" @on-change="timeChange" placement="bottom-end"
                        placeholder="请选择创建时间" v-model="list.search_item.created_at" class="w-200"></DatePicker>
          </FormItem>

          <FormItem>&nbsp;
            <Button type="text" class="main-font-color" @click="cancelSearch('list_search_item')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>

    <div id="list" class="main-list">
      <Table :columns="list.columns" :data="list.data">
        <template slot-scope="{ row }" slot="thumbnail_url">
          <p><img v-show="row.thumbnail_url" class="preview-img" :src="row.thumbnail_url" alt=""></p>
        </template>

        <template slot-scope="{ row }" slot="part_name">
          <span>{{ row.part_name + ' (' + row.part_no + ')' }}</span>
        </template>

        <template slot-scope="{ row }" slot="relation_no">
          <span class="pointer data-copy" @click="copyData(row.relation_no)">{{ row.relation_no }}<Icon type="ios-copy-outline"/></span>
        </template>

        <template slot-scope="{ row }" slot="created_at">
          <span>{{ row.created_at.substring(0, 10) }}</span>
        </template>

        <template slot-scope="{ row }" slot="goods_type">
          <span>{{ getGoodsType(row.goods_type) }}</span>
        </template>
      </Table>
      <div class="page">
        <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"
              :current="list.page" show-sizer @on-page-size-change="pageSizeChange" :page-size-opts="list.page_size_opts"/>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getStockLogList,
} from '@/api/goods/stockLog';
import {getGoodsCategoryList} from "@/api/goods/goodsCategory";

export default {
  name: "StockLog",
  data() {
    return {
      list:{
        page_size_opts: [10, 15, 20, 30],
        page_size_key: 'stock_log_page_size_key',
        search_item: {
          part_no: '',
          part_name: '',
          relation_no: '',
          created_at: []
        },
        columns: [
          {
            title: '缩略图',
            key: 'thumbnail_url',
            slot: 'thumbnail_url',
            align: 'center',
          },
          {
            title: '货品',
            key: 'part_name',
            slot: 'part_name',
            width: 260,
            align: 'center',
          },
          {
            title: '货品类型',
            key: 'goods_type',
            slot: 'goods_type',
            align: 'center',
          },
          {
            title: '变动数量',
            key: 'num',
            align: 'center',
          },
          {
            title: '操作人',
            key: 'operator_name',
            align: 'center',
          },
          {
            title: '关联单号',
            key: 'relation_no',
            slot: 'relation_no',
            width: 140,
            align: 'center',
          },
          {
            title: '操作时间',
            key: 'created_at',
            slot: 'created_at',
            align: 'center',
          },
          {
            title: '备注',
            key: 'remark',
            align: 'center',
          }
        ],
        data: []
      },
    }
  },
  methods: {
    getGoodsType(type) {
      let name = '';
      switch (type) {
        case 1:
          name = '物料';
          break;
        case 2:
          name = '成品';
          break;
        case 3:
          name = '半成品';
          break;
        case 4:
          name = '虚拟';
          break;
      }
      return name;
    },
    searchSubmit() {
      this.list.page = 1;
      this.getMainList();
    },
    pageSizeChange(page_size) {
      this.list.size = page_size;
      localStorage.setItem(this.list.page_size_key, page_size);
      this.getMainList();
    },
    changePage(page) {
      this.list.page = page;
      this.getMainList();
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.list.page = 1;
      this.getMainList();
    },
    timeChange(dateRange) {
      if (dateRange[0] && dateRange[1]) {
        this.list.search_item.created_at = dateRange;
      } else {
        this.list.search_item.created_at = "";
      }

      this.searchSubmit();
    },
    getMainList() {
      let param = {
        page: this.list.page,
        part_no: this.list.search_item.part_no,
        part_name: this.list.search_item.part_name,
        relation_no: this.list.search_item.relation_no,
        created_at: this.list.search_item.created_at,
        size: this.list.size
      };

      getStockLogList(param).then((res) => {
        for (let i=0; i<res.data.results.list.length; i++) {

          res.data.results.list[i].num = parseFloat(res.data.results.list[i].num);
        }
        this.list.data = res.data.results.list;
        this.list.total = res.data.results.total;
        this.list.size = res.data.results.size;
      });
    },

    copyData(number) {
      let clipboard = new this.clipboard('.data-copy', {
        text: function() {
          return number;
        }
      });
      clipboard.on('success', () => {
        this.$Message.success('复制成功！');
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        this.$Message.error('复制失败，请手动选择复制！');
        clipboard.destroy();
      });
    },
    setSelectedPageSize() {
      let size = localStorage.getItem(this.list.page_size_key);
      this.list.size = size ? (size > 50 ? 40 : parseInt(size)) : 0;
    },
  },
  mounted() {
    this.setSelectedPageSize();
    this.getMainList();
  }
}
</script>

<style scoped>
#stock-log .ivu-card {
  background-color: #fafafa;
}

#stock-log .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#stock-log .ivu-form-item {
  margin-bottom: 0;
}

#list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#list .page {
  margin-top: 16px;
}
</style>
