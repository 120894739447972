<template>
  <div id="clientInfo">
    <div id="panel-header">
      <span class="top-title">领料记录</span>
      <Button type="primary" class="client_create" @click="create('add_info.item')" v-if="aCheck.rightCheck('edit_picking_records')">
        申请领料
      </Button>
    </div>

    <div id="filter-bar">
      <Card style="width:100%" dis-hover>
        <Form ref="list_search_item" :model="list.search_item" inline>
          <FormItem prop="main_no">
            <Input @on-blur="searchSubmit()" v-model="list.search_item.main_no" class="w-200" placeholder="请填写领料号"/>
          </FormItem>

          <FormItem>&nbsp;
            <Button type="text" class="main-font-color" @click="cancelSearch('list_search_item')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>

    <div id="list" class="goods_list">
      <Table :columns="list.columns" :data="list.data">
        <template slot-scope="{ row }" slot="main_no">
          <strong @click="showDataInfo(row)" class="pointer">{{ row.main_no }}</strong>
        </template>
        <template slot-scope="{ row }" slot="status">
          <span :class="getStatusColor(row.status)">{{ getStatusText(row.status) }}</span>
        </template>

        <template slot-scope="{ row }" slot="updated_at">
          <span>{{ row.updated_at.substring(0, 10) }}</span>
        </template>

        <template slot-scope="{ row }" slot="type">
          <span v-for="item in common.all_type" v-if="item.id == row.type">{{ item.name }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="action">
          <Button v-show="row.status==0" class="fs-12" type="text" size="small" :class="getStatusColor(row.status)" @click="showDataInfo(row)" v-if="aCheck.rightCheck('valid_picking_records')">
            <Icon type="ios-checkmark-circle"/>
            审核
          </Button>
          <Button v-show="row.status==1" class="fs-12" type="text" size="small" :class="getStatusColor(row.status)" @click="showDataInfo(row)" v-if="aCheck.rightCheck('operate_picking_records')">
            <Icon type="ios-checkmark-circle"/>
            领料
          </Button>
          <Button v-show="[2, 4, 5].includes(row.status)" class="fs-12" type="text" size="small" style="color:#19be6b" @click="showDataInfo(row)">
            <Icon type="ios-checkmark-circle"/>
            查看
          </Button>
          <Button v-show="row.status==3" type="text" class="fs-12" size="small" style="color:#2d8cf0" @click="endPick(row)" v-if="aCheck.rightCheck('operate_picking_records')">
            <Icon type="ios-checkmark-circle"/>
            领料完成
          </Button>
          <Dropdown v-show="[0,1].includes(row.status)">
            <Button size="small" type="text" class="fs-12">
              更多
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list" v-if="aCheck.rightCheck('edit_picking_records')">
              <DropdownItem v-show="row.status==0">
                <span @click="listEdit(row)" class="f12">修改申请</span>
              </DropdownItem>
              <DropdownItem v-show="row.status==0">
                <span @click="listDeleteAlert(row)" class="f12">删除申请</span>
              </DropdownItem>
              <DropdownItem v-show="row.status == 1">
                <span @click="cancelBuyOrder(row)" class="f12">取消申请</span>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </template>
      </Table>
      <div class="page">
        <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"
              :current="list.page" show-sizer @on-page-size-change="pageSizeChange" :page-size-opts="list.page_size_opts"/>
      </div>

    </div>

    <Modal v-model="add_info.modal" :title="add_info.modal_title" width="1028">
      <div id="modalForm">
        <Form ref="add_info.item" :model="add_info.item" :label-width="100" :rules="add_info.ruleInline"
              label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="领料号" prop="main_no">
                <Input v-model="add_info.item.main_no" disabled placeholder="请填写领料号"></Input>
              </FormItem>
            </Col>

            <Col span="12">
              <FormItem label="类型" prop="type">
                <Select v-model="add_info.item.type" placeholder="请选择领料类型" filterable>
                  <Option v-for="item in common.all_type" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="领料理由" prop="reason">
                <Input v-model="add_info.item.reason" maxlength="255" placeholder="请填写领料理由"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="备注" prop="remark">
                <Input v-model="add_info.item.remark" maxlength="255" placeholder="请填写备注"></Input>
              </FormItem>
            </Col>
          </Row>

          <Row class="mt4 display-block">
            <Card :bordered="false">
              <p slot="title">领料明细</p>
              <Button type="primary" slot="extra" size="small" @click="addGoods(0)" class="f12">
                <Icon type="md-add"/>
                新增明细
              </Button>
              <Table :columns="add_info.goods.columns" :data="add_info.goods.data"
                     style="overflow: visible;">
                <template slot-scope="{ row }" slot="thumbnail_url">
                  <p><img v-show="row.thumbnail_url" class="preview-img" :src="row.thumbnail_url" alt=""></p>
                </template>

                <template slot-scope="{ row, index }" slot="real_total">
                  <span>{{ minus(row.stock_all, row.frozen_stock) }}</span>
                </template>

                <template slot-scope="{ row }" slot="goods_type">
                  <span>{{ getGoodsType(row.goods_type) }}</span>
                </template>

                <template slot-scope="{ row, index }" slot="need_num">
                    <i-input type="number" v-model="row.need_num" @on-change="setNeedNum(row, index)"/>
                </template>

                <template slot-scope="{ row, index }" slot="action">
                  <Button class="fs12" type="text" size="small" @click="deleteGoodsSelected(row, index)">
                    <Icon type="ios-trash"/>
                    删除
                  </Button>
                </template>
              </Table>
            </Card>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleReset('add_info.item')">取消</Button>
        <Button type="primary" :loading="add_info.modal_loading" @click="addGoodsPick('add_info.item')">保存</Button>
      </div>
    </Modal>

    <Modal v-model="back_info.modal" :title="back_info.modal_title" width="1028">
      <div id="modalForm">
        <Form ref="back_info.item" :model="back_info.item" :label-width="100"
              label-colon>
          <Row class="mt4 display-block">
            <Card :bordered="false">
              <p slot="title">领料退回明细</p>
              <Table :columns="back_info.columns" :data="back_info.data" style="overflow: visible;">
                <template slot-scope="{ row }" slot="thumbnail_url">
                  <p><img v-show="row.thumbnail_url" class="preview-img" :src="row.thumbnail_url" alt=""></p>
                </template>

                <template slot-scope="{ row }" slot="goods_type">
                  <span>{{ getGoodsType(row.goods_type) }}</span>
                </template>

                <template slot-scope="{ row, index }" slot="back_num">
                    <i-input type="number" v-model="row.back_num" @on-change="setBackNum(row, index)"/>
                </template>

                <template slot-scope="{ row, index }" slot="action">
                  <Button class="fs12" type="text" size="small" @click="deleteBackGoodsSelected(row, index)">
                    <Icon type="ios-trash"/>
                    删除
                  </Button>
                </template>
              </Table>
            </Card>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="backClose()">取消</Button>
        <Button type="primary" :loading="add_info.modal_loading" @click="backGoodsPick()">保存并入库</Button>
      </div>
    </Modal>

    <!--查看-->
    <Modal v-model="viewPick.modal" :title="viewPick.modal_title" width="1028">
      <div class="modalForm">
        <Form :label-width="100" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="领料号">
                <span>{{ viewPick.data.main_no }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="类型" prop="type">
                <span v-for="item in common.all_type" v-show="item.id==viewPick.data.type">{{ item.name }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="领料理由">
                <span>{{ viewPick.data.reason }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="状态">
                  <Breadcrumb separator=">">
                      <BreadcrumbItem v-for="item in status_text" :class="item.value==viewPick.data.status?item.color+' fw-700':''">{{item.label}}</BreadcrumbItem>
                  </Breadcrumb>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="备注">
                <span>{{ viewPick.data.remark }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row class="mt4 display-block">
            <Card dis-hover >
              <div>
                <span style="margin:0 10px" :class="common.view_flag == 1 ? 'select-flag main-font-color pointer': 'pointer'" @click="selectTap(1)">领料明细</span>
                <span style="margin:0 10px" :class="common.view_flag == 2 ? 'select-flag main-font-color pointer': 'pointer'" @click="selectTap(2)">退库明细</span>
                <Divider/>
              </div>
              <div v-show="common.view_flag == 1">
                <Table :columns="add_info.goods.columns" :data="viewPick.data.goods_setted"
                       style="overflow: visible;">
                  <template slot-scope="{ row }" slot="thumbnail_url">
                    <p><img v-show="row.thumbnail_url" class="preview-img" :src="row.thumbnail_url" alt=""></p>
                  </template>

                  <template slot-scope="{ row, index }" slot="real_total">
                    <span>{{ minus(row.stock_all, row.frozen_stock) }}</span>
                  </template>

                  <template slot-scope="{ row }" slot="goods_type">
                    <span>{{ getGoodsType(row.goods_type) }}</span>
                  </template>

                  <template slot-scope="{ row, index }" slot="need_num">
                    <span>{{ row.need_num }}</span>
                  </template>

                  <template slot-scope="{ row, index }" slot="action">
                    <span class="disabled-color fs-12">------</span>
                  </template>
                </Table>
              </div>
              <div v-show="common.view_flag == 2">
                <Table :columns="viewPick.back_columns" :data="viewPick.data.back_goods_set" style="overflow: visible;">
                  <template slot-scope="{ row }" slot="thumbnail_url">
                    <p><img v-show="row.thumbnail_url" class="preview-img" :src="row.thumbnail_url" alt=""></p>
                  </template>

                  <template slot-scope="{ row }" slot="goods_type">
                    <span>{{ getGoodsType(row.goods_type) }}</span>
                  </template>

                  <template slot-scope="{ row, index }" slot="goods_num">
                    <span>{{ row.goods_num }}</span>
                  </template>
                </Table>
              </div>
            </Card>

          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleResetOrderModal(viewPick)">取消</Button>
        <Button type="success" v-show="viewPick.data.status==0" :loading="viewPick.modal_loading"
                @click="passOrder(viewPick)">审核通过
        </Button>
        <Button type="primary" v-show="viewPick.data.status==1" :loading="viewPick.modal_loading"
                @click="startPick(viewPick)">开始领料
        </Button>
        <Button type="warning" v-show="viewPick.data.status==1" :loading="quick_pick.modal_loading"
                @click="quickPickOut(viewPick)" title="快捷领料，将会直接将货品出库，完成领料">快捷领料
        </Button>
        <Button type="warning" v-show="viewPick.data.status==4" @click="goodsBack(viewPick)">领料退回</Button>
      </div>
    </Modal>

    <SelectGoods ref="select_goods" @selected="getSelectedGoods" :select_goods="select_goods"/>
  </div>
</template>

<script>
import SelectGoods from '@/components/common/SelectGoods.vue';
import NP from "number-precision";
import {getGoodsType} from '@/utils/common'
import {apiGetPickStock, apiBackGoodsPick, apiQuickPickOutWarehouse} from "@/api/goods/goodsPick";

export default {
  name: "GoodsPick",
  data() {
    return {
      quick_pick: {
        modal_loading: false,
        item: {
          pick_id: 0,
        }
      },
      status_text: [
        {
          value: 0,
          label: '待审核',
          color: 'status-danger',
        },
        {
          value: 1,
          label: '待领料',
          color: 'status-main',
        },
        {
          value: 2,
          label: '领料中',
          color: 'status-info',
        },
        {
          value: 3,
          label: '已出库',
          color: 'status-normal',
        },
        {
          value: 4,
          label: '已领料',
          color: 'status-success',
        },
        {
          value: 5,
          label: '领料退库',
          color: 'status-main',
        },
      ],
      back_info: {
        modal: false,
        modal_title: '领料退回',
        data: [],
        pick_id: 0,
        columns: [
          {
            title: '货品编号',
            key: 'part_no',
            align: 'center'
          },
          {
            title: '货品名称',
            key: 'part_name',
            align: 'center'
          },
          {
            title: '缩略图',
            key: 'thumbnail_url',
            slot: 'thumbnail_url',
            align: 'center'
          },
          {
            title: '货品类型',
            key: 'goods_type',
            slot: 'goods_type',
            align: 'center'
          },
          {
            title: '领料剩余',
            key: 'need_num',
            align: 'center'
          },
          {
            title: '退回数量',
            key: 'back_num',
            slot: 'back_num',
            align: 'center'
          },
          {
            title: '操作',
            key: 'action',
            slot: 'action',
            width: 160,
            align: 'center'
          }
        ],
      },
      select_goods: {
        modal: false,
        count_flag: 0,
        goods_type: 0
      },
      common: {
        house_all: [],
        area_all: [],
        position_all:[],
        all_type: [
          {
            "id": 1,
            "name": "生产领料"
          },
          {
            "id": 2,
            "name": "行政领料"
          },
          {
            "id": 3,
            "name": "设计领料"
          }
        ],//所有领料类型
        detail: '',
        view_flag:1,
      },
      add_info: {
        item: {
          id: '',
          main_no: '',
          type: '',
          reason: '',
          remark: '',
        },
        modal_loading: false,
        modal_title: '创建领料记录',
        modal: false,
        ruleInline: {
          type: [
            {required: true, message: '请选择类型'}
          ],
          reason: [
            {required: true, message: '请填写领料理由'},
          ]
        },
        goods: {
          columns: [
            {
              title: '货品编号',
              key: 'part_no',
              align: 'center'
            },
            {
              title: '货品名称',
              key: 'part_name',
              align: 'center'
            },
            {
              title: '缩略图',
              key: 'thumbnail_url',
              slot: 'thumbnail_url',
              align: 'center'
            },
            {
              title: '货品类型',
              key: 'goods_type',
              slot: 'goods_type',
              align: 'center'
            },
            {
              title: '货品库存',
              key: 'real_total',
              slot: 'real_total',
              align: 'center'
            },
            {
              title: '领料数量',
              key: 'need_num',
              slot: 'need_num',
              align: 'center'
            },
            {
              title: '操作',
              key: 'action',
              slot: 'action',
              width: 160,
              align: 'center'
            }
          ],
          data: [],
        }
      },
      viewPick: {
        modal: false,
        modal_title: '查看详情',
        modal_loading: false,
        data: {},
        back_columns: [
          {
            title: '货品编号',
            key: 'part_no',
            align: 'center'
          },
          {
            title: '货品名称',
            key: 'part_name',
            align: 'center'
          },
          {
            title: '缩略图',
            key: 'thumbnail_url',
            slot: 'thumbnail_url',
            align: 'center'
          },
          {
            title: '货品类型',
            key: 'goods_type',
            slot: 'goods_type',
            align: 'center'
          },
          {
            title: '退库数量',
            key: 'goods_num',
            align: 'center'
          }
        ]
      },
      goods_type: 2,//1表示物料 2表示成品
      formItem: {
        main_no: '',
        part_name: '',
      },
      clientFormItem: {
        id: '',
        main_no: '',
      },

      list: {
        columns: [
          {
            title: '领料号',
            key: 'main_no',
            slot: 'main_no',
            align: 'center',
          },
          {
            title: '类型',
            slot: 'type',
            key: 'type',
            align: 'center',
          },
          {
            title: '申请人',
            key: 'applicant_name',
            align: 'center',
          },
          {
            title: '申请理由',
            key: 'reason',
            width:200,
            align: 'center',
          },
          {
            title: '申请状态',
            key: 'status',
            slot: 'status',
            align: 'center',
          },
          {
            title: '操作时间',
            key: 'updated_at',
            slot: 'updated_at',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
            width: 160,
          }
        ],
        page_size_opts: [10, 15, 20, 30],
        page_size_key: 'goods_pick_page_size_key',
        data: [],
        search_item: {
          main_no: '',
        },
        page: 1,
        total: 0,
        size: 0
      },
    };
  },
  methods: {
    quickPickOut(item) {
      this.$Modal.confirm({
        title: "快捷领料操作",
        content: '快捷领料操作将会自动扣除仓库相关货物库存，以快捷方式完成领料操作！确认进行操作？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.quick_pick.item.pick_id = item.data.id;
          this.quick_pick.item.main_no = item.data.main_no;

          this.quick_pick.modal_loading = true;
          apiQuickPickOutWarehouse(this.quick_pick.item).then(res => {
            if (res.data.err_no === 0) {
              this.$Message.success(res.data.results);
              this.viewPick.modal = false;
              this.getPickList();
            } else {
              this.$Message.error(res.data.err_msg);
            }
            this.quick_pick.modal_loading = false;
          }).catch((error) => {
            this.$Message.error('操作失败');
            this.quick_pick.modal_loading = false;
          });
        },
        onCancel: () => {
        }
      });
    },
    selectTap(flag) {
      this.common.view_flag = flag;
    },
    getGoodsType (type) {
      return getGoodsType(type);
    },
    minus(a, b) {
      return NP.minus(a, b)
    },
    //审核弹出框重置
    handleResetOrderModal(item) {
      item.modal = false;
    },
    getStatusColor(index) {
      let rs = '';
      switch (index) {
        case 0:
          rs = 'status-danger';
          break;
        case 1:
          rs = 'status-main';
          break;
        case 2:
          rs = 'status-info';
          break;
        case 3:
          rs = 'status-normal';
          break;
        case 4:
          rs = 'status-success';
          break;
        case 5:
          rs = 'status-main';
          break;
      }

      return rs;
    },
    getStatusText(index) {
      let rs = '';
      switch (index) {
        case 0:
          rs = '待审核';
          break;
        case 1:
          rs = '待领料';
          break;
        case 2:
          rs = '领料中';
          break;
        case 3:
          rs = '已出库';
          break;
        case 4:
          rs = '已领料';
          break;
        case 5:
          rs = '领料退库';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    setNeedNum(row, index) {
      this.add_info.goods.data[index].need_num = row.need_num;
    },
    setBackNum(row, index) {
      this.back_info.data[index].back_num = row.back_num;
    },
    //审核通过
    passOrder(item) {
      item.modal_loading = true;

      let param = {
        id: item.data.id,
        main_no: item.data.main_no,
      };

      this.$axios.post('/api/goods/passGoodsPickOrder', param).then((response) => {
        if (response.data.err_no == 0) {
          this.$Message.success(response.data.results);
          this.getPickList();
          item.modal = false;
          //加载按钮不在这里关闭
        } else {
          this.$Message.error(response.data.err_msg);
          item.modal_loading = false;
        }

      })
      .catch((error) => {
        this.$Message.error('操作失败');
        item.modal_loading = false;
      });
    },
    //领料退回并入库
    backGoodsPick() {
      let param = {
        pick_id: this.back_info.pick_id,
        goods_info: this.back_info.data,
        pick_type: this.back_info.pick_type,
        main_no: this.back_info.main_no,
      }
      apiBackGoodsPick(param).then(res => {
        if (res.data.err_no == 0) {
          this.$Message.success(res.data.results);
          this.back_info.modal = false;
          this.back_info.data = [];
          this.viewPick.modal = false;
          this.getPickList();
        } else {
          this.$Message.error(res.data.err_msg);
        }
      })
    },
    //领料退回
    goodsBack(item) {
      let goods_id = [];
      for (let i=0; i<item.data.goods_setted.length; i++) {
        goods_id.push(item.data.goods_setted[i].goods_id);
      }

      //获取领料库存信息
      let param = {
        pick_type: item.data.type,
        goods_id: goods_id
      }
      apiGetPickStock(param).then(res => {
        if (res.data.err_no == 0) {
          let show_flag = false;
          let result = res.data.results;
          for (let i=0; i<item.data.goods_setted.length; i++) {
            for (let j=0; j<result.length; j++) {
              if (item.data.goods_setted[i].goods_id == result[j].goods_id) {
                if (item.data.goods_setted[i].need_num > result[j].goods_num) {
                  item.data.goods_setted[i].need_num = parseFloat(result[j].goods_num);
                  item.data.goods_setted[i].back_num = parseFloat(result[j].goods_num);
                } else {
                  item.data.goods_setted[i].back_num = parseFloat(item.data.goods_setted[i].need_num);
                }

                if (item.data.goods_setted[i].need_num > 0) {
                  show_flag = true;
                }
                break;
              }
            }
          }

          if (show_flag) {
            this.back_info.main_no = item.data.main_no;
            this.back_info.pick_id = item.data.id;
            this.back_info.pick_type = item.data.type;
            this.back_info.data = item.data.goods_setted;
            this.back_info.modal = true;
          } else {
            this.$Message.error('已无领料货品可退，请留意！');
          }
        }
      });
    },
    //开始领料
    startPick(item) {
      item.modal_loading = true;

      let param = {
        id: item.data.id,
        main_no: item.data.main_no,
      };
      this.$axios.post('/api/goods/startGoodsPick', param).then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.getPickList();
              item.modal = false;
              //加载按钮不在这里关闭
            } else {
              this.$Message.error(response.data.err_msg);
              item.modal_loading = false;
            }
          })
          .catch((error) => {
            this.$Message.error('操作失败');
            item.modal_loading = false;
          });
    },
    // 去除已选择的货品
    deleteGoodsSelected(row, index) {
      this.add_info.goods.data.splice(index, 1);
    },
    deleteBackGoodsSelected(row, index) {
      this.back_info.data.splice(index, 1);
    },
    addGoods(goods_type) {
      this.$refs.select_goods.select_goods.goods_type = goods_type;
      this.$refs.select_goods.showGoodsList();
    },
    //获取选中的货品
    getSelectedGoods(val) {
      //设置选中的货品
      let selected_goods = val;
      let setted_goods = this.add_info.goods.data;
      if (selected_goods.length) {
        for (let i = 0, len = selected_goods.length; i < len; i++) {
          //列表必要参数赋值及初始化
          selected_goods[i].need_num = '';
          selected_goods[i].goods_id = selected_goods[i].id;
          setted_goods.push(selected_goods[i]);
        }
      }
    },

    //打开查看页面
    showDataInfo(row) {
      this.viewPick.modal_loading = false;
      this.viewPick.data = row;
      this.$axios.get('/api/goods/getGoodsPickDetail?id=' + row.id).then((res) => {
        if (res.data.err_no === 0) {
          for (let i=0; i<res.data.results.goods_setted.length; i++) {
            res.data.results.goods_setted[i].need_num = parseFloat(res.data.results.goods_setted[i].need_num);
          }

          for (let i=0; i<res.data.results.back_goods_set.length; i++) {
            res.data.results.back_goods_set[i].goods_num = parseFloat(res.data.results.back_goods_set[i].goods_num);
          }

          this.viewPick.data.goods_setted = res.data.results.goods_setted;
          this.viewPick.data.back_goods_set = res.data.results.back_goods_set;

          this.viewPick.modal = true;
        }
      });
    },
    //完成领料
    endPick(row) {
      this.$Modal.confirm({
        title: "完成领料操作",
        content: '货品已出库完毕，确定领料完成？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          let param = {
            id: row.id,
            main_no: row.main_no,
          };
          this.$axios.post('/api/goods/goodsPickFinish', param).then((response) => {
            if (response.data.err_no === 0) {
              this.$Message.success(response.data.results);
              this.getPickList();
            } else {
              this.$Message.error(response.data.err_msg);
            }
          })
          .catch((error) => {
            this.$Message.error('操作失败');
          });
        },
        onCancel: () => {
        }
      });
    },

    handleReset(name) {
      //弹出框重置
      this.add_info.modal = false;
      this.add_info.modal_loading = false;
    },
    create(name) {
      //创建货品信息
      this.add_info.modal_loading = false;
      this.add_info.modal = true;
      this.$refs[name].resetFields();
      this.add_info.goods.data = [];
      this.$axios.get('/api/goods/getPickMainNo').then((response) => {
        if (response.data.err_no == 0) {
          this.add_info.item.main_no = response.data.results;
        }
      });
    },
    listEdit(row) {
      this.$axios.get('/api/goods/getGoodsPickDetail?id=' + row.id).then((response) => {
        if (response.data.err_no === 0) {
          this.add_info.item = response.data.results;
          this.add_info.goods.data = this.add_info.item.goods_setted;
        }
      });

      this.add_info.modal = true;
      this.add_info.modal_loading = false;
    },
    listDeleteAlert(row) {
      this.$Modal.confirm({
        title: "删除领料申请单信息",
        content: '确定领料申请单信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.deleteOperate(row.id, row.main_no);
        },
        onCancel: () => {
        }
      });
    },
    deleteOperate($id, $main_no) {
      let param = {
        id: $id,
        main_no: $main_no
      };
      this.$axios.post('/api/goods/deleteGoodsPick', param).then((response) => {
        if (response.data.err_no == 0) {
          this.$Message.success(response.data.results);
          this.getPickList();
        } else {
          this.$Message.error(response.data.err_msg);
        }
      });
    },
    backClose() {
      this.back_info.modal = false;
    },
    addGoodsPick(name) {
      this.add_info.modal_loading = true;

      this.$refs[name].validate((valid) => {
        if (valid) {
          let param = {
            id: this.add_info.item.id,
            main_no: this.add_info.item.main_no,
            type: this.add_info.item.type,
            reason: this.add_info.item.reason,
            remark: this.add_info.item.remark,
            goods_info: this.add_info.goods.data,
          };

          if (this.add_info.goods.data.length <= 0) {
            this.$Message.error('领料货品不能为空');
            this.add_info.modal_loading = false;
            return;
          }

          for (let i = 0; i < this.add_info.goods.data.length; i++) {
            let need_num = this.add_info.goods.data[i].need_num;
            let stock_all = this.add_info.goods.data[i].stock_all - this.add_info.goods.data[i].frozen_stock;

            if (need_num <= 0) {
              this.$Message.error('领料数量不能小于等于0');
              this.add_info.modal_loading = false;
              return;
            }

            if (need_num > stock_all) {
              this.$Message.error('领料数量不能大于库存数量');
              this.add_info.modal_loading = false;
              return;
            }
          }

          this.$axios.post('/api/goods/addGoodsPick', param).then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.$refs[name].resetFields();
              this.add_info.modal = false;
              this.getPickList();
            } else {
              this.$Message.error(response.data.err_msg);
              this.add_info.modal_loading = false;
            }
          }).catch((error) => {
            this.$Message.error('保存失败');
            this.add_info.modal_loading = false;
          });
        } else {
          this.add_info.modal_loading = false;
        }
      });

    },
    searchSubmit() {
      this.list.page = 1;
      this.getPickList();
    },
    cancelSearch(name) {
      this.list.page = 1;
      this.$refs[name].resetFields();
      this.getPickList();
    },
    getPickList() {
      //获取货品列表
      let param = {
          page: this.list.page,
          main_no: this.list.search_item.main_no,
          size:this.list.size
      };
      this.$axios.post('/api/goods/getPickList', param).then((response) => {
        if (response.data.err_no == 0) {
          this.list.data = response.data.results.list;
          this.list.total = response.data.results.total;
          this.list.size = response.data.results.size;
        }
      });
    },
    changePage(page) {
      this.list.page = page;
      this.getPickList();
    },
    pageSizeChange(page_size) {
      this.list.size = page_size;
      localStorage.setItem(this.list.page_size_key, page_size);
      this.getPickList();
    },
    setSelectedPageSize() {
      let size = localStorage.getItem(this.list.page_size_key);
      this.list.size = size ? (size > 50 ? 40 : parseInt(size)) : 0;
    },
  },
  mounted() {
    this.setSelectedPageSize();
    this.getPickList();
  },
  created() {
    this.aCheck.plateName = 'product_manage';
  },
  components: {
    SelectGoods,
  }
};
</script>

<style scoped>
#clientInfo {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

.fleft {
  float: left;
}

.ml10 {
  margin-left: 10px;
}

#panel-header {
  position: relative;
  border: none;
}

#modalForm {
  width: 100%;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

.client_create {
  position: absolute;
  right: 0;
  top: -8px;
}

.client_import {
  position: absolute;
  right: 100px;
  top: -8px;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#list .page {
  margin-top: 16px;
}

.select-flag {
  font-size: 16px;
  font-weight: 500;
}
</style>
<style>
#clientInfo .ivu-card {
  background-color: #fafafa;
}

#clientInfo .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#clientInfo .ivu-form-item {
  margin-bottom: 0;
}

#list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#list .ivu-page {
  float: right;
}

.goods_list .ivu-table-overflowX, .ivu-table-tip {
  overflow-x: hidden;
}

.ivu-breadcrumb>span:last-child {
  font-weight: normal;
  color: #999;
}
</style>
