import request from '@/plugins/http'

export function getShipOutList(param) {
    return request.post('/api/goods/getShipOutList', param);
}

export function getOutInfoForShip(param) {
    return request.get('/api/goods/getOutInfoForShip', param);
}

//开始发货
export function startShipOut(param) {
    return request.post('/api/goods/startShipOut', param);
}

//绑定物流信息
export function shipOutBindLogistics(param) {
    return request.post('/api/goods/shipOutBindLogistics', param);
}

export function apiGetShipOutLogisticsInfo(param) {
    return request.post('/api/goods/getShipOutLogisticsInfo', param);
}

//去除发货单绑定的快递单信息
export function apiDeleteShipBindLogisticsDetail(param) {
    return request.post('/api/goods/deleteShipBindLogisticsDetail', param);
}

export function apiFinisShipOut(param) {
    return request.post('/api/goods/finishShipOut', param);
}


