import request from '@/plugins/http'

export function getHouseAreaAll(house_id) {
    return request.get('/api/setting/getHouseAreaAll?house_id=' + house_id);
}

export function getHousePositionAll(area_id) {
    return request.get('/api/setting/getHousePositionAll?area_id=' + area_id);
}

export function pushOutFinish(param) {
    return request.post('/api/goods/pushOutFinish', param);
}

export function getGoodsOutMainNo() {
    return request.get('/api/goods/getGoodsOutMainNo');
}

export function goodsOutByHands(param) {
    return request.post('/api/goods/goodsOutByHands', param);
}

export function apiPushOutBatch(param) {
    return request.post('/api/goods/pushOutBatch', param);
}








