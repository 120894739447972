
/**

 * 将svg导出成图片

 * @param node svg节点 => document.querySelector('svg')

 * @param name 生成的图片名称

 * @param type 生成的图片类型

 */

export const covertSVG2Image = (node, name, type = 'png') => {

    let serializer = new XMLSerializer()

    let source = '<?xml version="1.0" standalone="no"?>\r\n' + serializer.serializeToString(node)

    let image = new Image()

    image.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(source)

    let canvas = document.createElement('canvas')
    canvas.width = node.attributes.width.value.slice(0, -2);
    canvas.height = node.attributes.height.value.slice(0, -2);

    let context = canvas.getContext('2d')

    context.fillStyle = '#fff'

    context.fillRect(0, 0, 10000, 10000)

    image.onload = function () {

        context.drawImage(image, 0, 0)

        let a = document.createElement("a");

        a.download = `${name}.${type}`

        let url = canvas.toDataURL("image/png");

        // 新建点击事件
        let event = new MouseEvent("click");
        // 将图片的 base64 编码，设置为 a标签的地址
        a.href = url;
        // 触发点击事件
        a.dispatchEvent(event);
    }

}
