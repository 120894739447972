import request from '@/plugins/http'

export function pushInBatch(param) {
    return request.post('/api/goods/pushInBatch', param);
}

export function getGoodsInMainNO() {
    return request.get('/api/goods/getGoodsInId');
}

export function goodsInByHands(param) {
    return request.post('/api/goods/goodsInByHands', param);
}

