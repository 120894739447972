import request from '@/plugins/http'

export function createStockTransfer(param) {
    return request.post('/api/goods/createStockTransfer', param);
}

export function getStockTransferMainNo() {
    return request.post('/api/goods/getStockTransferMainNo');
}

export function getStockTransferList(param) {
    return request.post('/api/goods/getStockTransferList', param);
}

export function getStockTransferDetail(param) {
    return request.post('/api/goods/getStockTransferDetail', param);
}

export function passStockTransferStatus(param) {
    return request.post('/api/goods/passStockTransferStatus', param);
}

export function deleteStockTransfer(param) {
    return request.post('/api/goods/deleteStockTransfer', param);
}

export function startStockTransfer(param) {
    return request.post('/api/goods/startStockTransfer', param);
}

export function goodsInStockTransfer(param) {
    return request.post('/api/goods/goodsInStockTransfer', param);
}










