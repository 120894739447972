import request from "@/plugins/http";

export function apiGetPickStock(param) {
    return request.post('/api/goods/getPickStock', param);
}

export function apiBackGoodsPick(param) {
    return request.post('/api/goods/backGoodsPick', param);
}

export function apiQuickPickOutWarehouse(param) {
    return request.post('/api/goods/quickPickOutWarehouse', param);
}






