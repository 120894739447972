<template>
  <div id="stock-transfer" class="module-body">
    <div id="panel-header" class="panel-header">
      <span class="top-title">库存调拨</span>
      <Button type="primary" class="item_create" @click="itemCreate('add_item')" v-if="aCheck.rightCheck('edit_stock_transfer')">
        创建调拨
      </Button>
    </div>

    <div id="filter-bar" class="filter-bar">
      <Card dis-hover>
        <Form ref="list_search_item" :model="list.search_item" inline>
          <FormItem prop="main_no">
            <Input @on-blur="searchSubmit" v-model="list.search_item.main_no" placeholder="请填写货品号" class="w-200"/>
          </FormItem>

          <FormItem prop="target_house_id">
            <Select @on-change="searchSubmit" v-model="list.search_item.target_house_id" placeholder="请选择目标仓库" filterable class="w-200">
              <Option v-for="item in common.warehouse_all" :value="item.house_id" :key="item.house_id">{{ item.house_name }}</Option>
            </Select>
          </FormItem>

          <FormItem prop="from_house_id">
            <Select @on-change="searchSubmit" v-model="list.search_item.from_house_id" placeholder="请选择目标仓库" filterable class="w-200">
              <Option v-for="item in common.warehouse_all" :value="item.house_id" :key="item.house_id">{{ item.house_name }}</Option>
            </Select>
          </FormItem>

            <FormItem prop="created_at">
              <DatePicker type="daterange" @on-change="timeChange" placement="bottom-end"
                          placeholder="请选择创建时间" v-model="list.search_item.created_at" class="w-200"></DatePicker>
            </FormItem>

          <FormItem>&nbsp;
            <Button type="text" class="main-font-color" @click="cancelSearch('list_search_item')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>

    <div id="list" class="main-list">
      <Table :columns="list.columns" :data="list.data">

        <template slot-scope="{ row }" slot="main_no">
          <strong @click="viewItem(row)" class="pointer">{{row.main_no}}</strong>
        </template>

        <template slot-scope="{ row }" slot="target_house_id">
          <span v-for="item in common.warehouse_all" v-if="item.house_id==row.target_house_id">{{item.house_name}}</span>
        </template>

        <template slot-scope="{ row }" slot="from_house_id">
          <span v-for="item in common.warehouse_all" v-if="item.house_id==row.from_house_id">{{item.house_name}}</span>
        </template>

        <template slot-scope="{ row }" slot="status">
          <span :style="getStatusColor(row.status)">{{ getStatusText(row.status) }}</span>
        </template>

        <template slot-scope="{ row }" slot="updated_at">
          <span>{{ row.updated_at.substring(0, 10) }}</span>
        </template>

        <template slot-scope="{ row, index }" slot="action">
          <!--待审核情况下显示-->
          <Button v-show="[0].includes(row.status)" type="text" size="small" :style="getStatusColor(row.status)" @click="viewItem(row)" v-if="aCheck.rightCheck('valid_stock_transfer')">
            <Icon type="ios-checkmark-circle"/>
            审核
          </Button>
          <Button v-show="[2, 4, 5, 6].includes(row.status)" type="text" size="small" :style="getStatusColor(row.status)" @click="viewItem(row)">
            <Icon type="ios-checkmark-circle"/>
            查看
          </Button>
          <Button v-show="[1].includes(row.status)" type="text" size="small" :style="getStatusColor(row.status)" @click="startItem(row)" v-if="aCheck.rightCheck('operate_stock_transfer')">
            <Icon type="ios-checkmark-circle"/>
            执行
          </Button>
          <Button v-show="[3].includes(row.status)" type="text" size="small" :style="getStatusColor(row.status)" @click="goodsInItem(row)" v-if="aCheck.rightCheck('operate_stock_transfer')">
            <Icon type="ios-checkmark-circle"/>
            转入库
          </Button>
          <Dropdown v-show="[0].includes(row.status)" v-if="aCheck.rightCheck('edit_stock_transfer')">
            <Button size="small" type="text">
              更多
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem v-show="[0].includes(row.status)">
                <span @click="editItem(row)" class="f12">修改</span>
              </DropdownItem>
              <DropdownItem v-show="[0].includes(row.status)">
                <span @click="deleteItem(row)" class="f12">删除</span>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </template>
      </Table>
      <div class="page">
        <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"
              :current="list.page" show-sizer @on-page-size-change="pageSizeChange" :page-size-opts="list.page_size_opts"/>
      </div>
    </div>

    <Modal v-model="add_item.modal" :title="add_item.modal_title" width="800">
      <div id="modalForm">
        <Form ref="add_item" :model="add_item.data" :label-width="100" :rules="add_item.ruleInline" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="调拨单号" prop="main_no">
                <Input v-model="add_item.data.main_no" disabled placeholder="请填写调拨单号"></Input>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="源仓库" prop="from_house_id">
                <Select v-model="add_item.data.from_house_id" placeholder="请选择源仓库">
                  <Option v-for="item in common.warehouse_all" :value="item.house_id" :key="item.house_id">{{ item.house_name }}</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="目标仓库" prop="target_house_id">
                <Select v-model="add_item.data.target_house_id" placeholder="请选择目标仓库" @on-change="checkHouseId">
                  <Option v-for="item in common.warehouse_all" :value="item.house_id" :key="item.house_id">{{ item.house_name }}</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="备注" prop="remark">
                <Input v-model="add_item.data.remark" maxlength="255" placeholder="请填写备注"></Input>
              </FormItem>
            </Col>
          </Row>

          <Row class="mt2 display-block">
            <Card :bordered="false">
              <p slot="title">调拨货品</p>
              <Button type="primary" slot="extra" size="small" @click="addGoods()" class="f12">
                <Icon type="md-add"/>
                选择货品
              </Button>
              <Table :columns="common.goods_columns" :data="add_item.data.goods_set">
                <template slot-scope="{ row }" slot="thumbnail_url">
                  <p><img v-show="row.thumbnail_url" class="preview-img" :src="row.thumbnail_url" alt=""></p>
                </template>
                <template slot-scope="{ row, index }" slot="transfer_num">
                  <p>
                    <i-input type="number" v-model="row.transfer_num" @on-change="setTransferNum(row, index)"/>
                  </p>
                </template>
                <template slot-scope="{ row, index }" slot="house_num">
                  <span>{{ row.house_num !== undefined ? row.house_num : 0 }}</span>
                </template>

                <template slot-scope="{ row, index }" slot="action">
                  <Button class="fs12" type="text" size="small" @click="deleteGoodsSelected(row, index)">
                    <Icon type="ios-trash"/>
                    删除
                  </Button>
                </template>
              </Table>
            </Card>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="cancelAdd">取消</Button>
        <Button type="primary" :loading="add_item.modal_loading" @click="createTransfer('add_item')">保存
        </Button>
      </div>
    </Modal>

    <!--审核-->
    <Modal v-model="view_item.modal" :title="view_item.modal_title" width="960">
      <div class="modalForm">
        <Form :model="view_item.data" :label-width="100" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="调拨单号" prop="main_no">
                <span>{{view_item.data.main_no}}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="源仓库" prop="main_no">
                <span v-for="item in common.warehouse_all" v-if="item.house_id==view_item.data.from_house_id">{{item.house_name}}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="目标仓库" prop="main_no">
                <span v-for="item in common.warehouse_all" v-if="item.house_id==view_item.data.target_house_id">{{item.house_name}}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="备注" prop="remark">
                <span>{{view_item.data.remark}}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="操作人" prop="operator_name">
                <span>{{view_item.data.operator_name}}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="当前进度" prop="status">
                <span :style="getStatusColor(view_item.data.status)">{{getStatusText(view_item.data.status)}}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="操作时间" prop="updated_at">
                <span>{{view_item.data.updated_at}}</span>
              </FormItem>
            </Col>
          </Row>
          <Row class="mt2 display-block">
            <Card :bordered="false">
              <p slot="title">调拨货品</p>
              <Table :columns="common.goods_columns" :data="view_item.data.goods_set">
                <template slot-scope="{ row }" slot="thumbnail_url">
                  <p><img v-show="row.thumbnail_url" class="preview-img" :src="row.thumbnail_url" alt=""></p>
                </template>
                <template slot-scope="{ row, index }" slot="transfer_num">
                  <span>{{row.transfer_num}}</span>
                </template>
                <template slot-scope="{ row, index }" slot="house_num">
                  <span>{{ row.house_num !== undefined ? row.house_num : 0 }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                  <span class="fs12 color-grey">------</span>
                </template>
              </Table>
            </Card>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="cancelView()">取消</Button>
        <Button v-show="[0].includes(view_item.data.status)" type="primary" :loading="view_item.modal_loading" @click="checkStockTransfer" v-if="aCheck.rightCheck('valid_stock_transfer')">审核</Button>
      </div>
    </Modal>

    <SelectGoods ref="select_goods" @selected="getSelectedGoods" :select_goods="select_goods"/>
  </div>
</template>

<script>
import '@/css/common.css';
import * as warehouseUtil from '@/utils/warehouse';
import SelectGoods from '@/components/common/SelectGoods.vue';
import {
  createStockTransfer,
  getStockTransferMainNo,
  getStockTransferList,
  getStockTransferDetail,
  passStockTransferStatus,
  deleteStockTransfer,
  startStockTransfer,
  goodsInStockTransfer
} from '@/api/goods/stockTransfer';

export default {
  name: "StockTransfer",
  data() {
    return {
      select_goods: {
        modal: false,
        count_flag: 0,
        goods_type: 0,//1表示货品 2表示成品 3半成品 0代表全部
        client_id: 0,
        house_id: 0,
      },
      list: {
        page_size_key: 'stock_transfer_page_size_key',
        page_size_opts: [10, 15, 20, 30],
        search_item: {
          main_no: '',
          target_house_id: '',
          from_house_id: '',
          created_at: []
        },
        columns: [
          {
            title: '调拨单号',
            key: 'main_no',
            slot: 'main_no',
            align: 'center',
          },
          {
            title: '目标仓库',
            key: 'target_house_id',
            slot: 'target_house_id',
            align: 'center',
          },
          {
            title: '源仓库',
            key: 'from_house_id',
            slot: 'from_house_id',
            align: 'center',
          },
          {
            title: '当下进度',
            key: 'status',
            slot: 'status',
            align: 'center',
          },
          {
            title: '操作人',
            key: 'operator_name',
            align: 'center',
          },
          {
            title: '操作时间',
            key: 'updated_at',
            slot: 'updated_at',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            slot: 'action',
            align: 'center',
          },
        ],
        data: []
      },
      add_item: {
        modal: false,
        modal_loading: false,
        modal_title: '创建库存调拨',
        data: {
          main_no: 0,
          id: 0,
          target_house_id: 0,
          from_house_id: 0,
          remark: '',
          operator_name: '',
          goods_set: [],
          status: 0,
        },
        ruleInline: {
          from_house_id: [
            {required: true, message: '请选择源仓库'}
          ],
          target_house_id: [
            {required: true, message: '请选择目标仓库'},
          ],
        }
      },
      view_item:{
        modal: false,
        modal_loading: false,
        modal_title: '查看库存调拨',
        data: {
          main_no:0,
          id: 0,
          target_house_id: 0,
          from_house_id: 0,
          remark: '',
          goods_set: []
        },
      },
      common: {
        warehouse_all: [],
        goods_columns: [
          {
            title: '货品编号',
            key: 'part_no',
            align: 'center',
          },
          {
            title: '货品名称',
            key: 'part_name',
            align: 'center',
          },
          {
            title: '缩略图',
            key: 'thumbnail_url',
            slot: 'thumbnail_url',
            align: 'center',
          },
          {
            title: '调拨数量',
            key: 'transfer_num',
            slot: 'transfer_num',
            align: 'center',
          },
          {
            title: '源库存',
            slot: 'house_num',
            key: 'house_num',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            slot: 'action'
          }
        ],
      }
    }
  },
  methods: {
    searchSubmit() {
      this.list.page = 1;
      this.getMainList();
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.getMainList();
    },
    timeChange(dateRange) {
      if (dateRange[0] && dateRange[1]) {
        this.list.search_item.created_at = dateRange;
      } else {
        this.list.search_item.created_at = "";
      }

      this.searchSubmit();
    },
    checkStockTransfer() {
      this.view_item.modal_loading = true;

      let param = {
        transfer_id: this.view_item.data.id,
        main_no: this.view_item.main_no
      }
      passStockTransferStatus(param).then(res => {
        this.view_item.modal_loading = false;
        if (res.data.err_no == 0) {
          this.$Message.success('审核操作成功');
          this.view_item.modal = false;
          this.getMainList();
        }
      }).catch(error => {
        this.$Message.success('审核操作失败');
        this.view_item.modal_loading = false;
      });
    },
    getStatusColor(index) {
      let rs = '';
      switch (index) {
        case 0:
          rs = 'color:#ed4014';//红色
          break;
        case 1:
          rs = 'color:#ff9900';//橙色
          break;
        case 2:
        case 3:
        case 4:
        case 5:
          rs = 'color:#2775ff';//蓝色
          break;
        case 6:
          rs = 'color:#19be6b';//绿色
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getStatusText(index) {
      let rs = '';
      switch (index) {
        case 0:
          rs = '待审核';
          break;
        case 1:
          rs = '待执行';
          break;
        case 2:
          rs = '待源库出库';
          break;
        case 3:
          rs = '源库已出库';
          break;
        case 4:
          rs = '入库待上架';
          break;
        case 5:
          rs = '入库上架中';
          break;
        case 6:
          rs = '完成';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    setTransferNum(row, index) {
      this.add_item.data.goods_set[index].transfer_num = row.transfer_num;
    },
    createTransfer(name) {
      this.add_item.modal_loading = true;

      this.$refs[name].validate((valid) => {
        if (valid) {
          //判断是否添加了销售货品
          let goods_set = [];
          if (this.add_item.data.goods_set.length < 1) {
            this.$Message.error('请添加调拨货品');
            this.add_item.modal_loading = true;
            return;
          }

          //过滤无效数据
          for (let i = 0; i < this.add_item.data.goods_set.length; i++) {
            let tmp = {
              goods_id: '',
              transfer_num: 0,
              house_num: 0,
            };

            tmp.goods_id = this.add_item.data.goods_set[i].goods_id;
            tmp.transfer_num = this.add_item.data.goods_set[i].transfer_num;
            tmp.house_num = this.add_item.data.goods_set[i].house_num;

            if (parseInt(tmp.transfer_num) <= 0) {
              this.$Message.error('调拨货品数量不能小于等于0');
              this.add_item.modal_loading = false;
              return;
            }

            if (parseInt(tmp.transfer_num) > parseInt(tmp.house_num)) {
              this.$Message.error('调拨货品数量不能大于源仓库数量');
              this.add_item.modal_loading = false;
              return;
            }
            goods_set[i] = tmp;
          }

          if (this.add_item.data.from_house_id === this.add_item.data.target_house_id) {
            this.$Message.error('源仓库不能和目标仓库相同');
            this.add_item.modal_loading = false;
            return;
          }

          let param = {
            id: this.add_item.data.id,
            main_no: this.add_item.data.main_no,
            from_house_id: this.add_item.data.from_house_id,
            target_house_id: this.add_item.data.target_house_id,
            remark: this.add_item.data.remark,
            goods_set: goods_set,
          };

          createStockTransfer(param).then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.add_item.modal = false;
              this.getMainList();
            } else {
              this.$Message.error(response.data.err_msg);
              this.add_item.modal_loading = false;
            }
          }).catch((error) => {
            this.$Message.error('保存失败');
            this.add_item.modal_loading = false;
          });
        } else {
          this.add_item.modal_loading = false;
        }
      });
    },
    cancelAdd() {
      //弹出框重置
      this.add_item.modal = false;
      this.add_item.modal_loading = false;
    },
    cancelView() {
      //弹出框重置
      this.view_item.modal = false;
    },
    checkHouseId() {
      if (this.add_item.data.target_house_id && this.add_item.data.from_house_id == this.add_item.data.target_house_id) {
        this.$Message.error('源仓库和目标仓库不能相同！！！');
        this.add_item.data.target_house_id = 0;
      }
    },
    // 去除已选择的货品
    deleteGoodsSelected(row, index) {
      this.add_item.data.goods_set.splice(index, 1);
    },
    //添加货品
    addGoods() {
      if (!this.add_item.data.from_house_id) {
        this.$Message.error('请选择源仓库');
        return;
      }

      this.$refs.select_goods.select_goods.house_id = this.add_item.data.from_house_id;
      this.$refs.select_goods.select_goods.goods_type = this.select_goods.goods_type;
      this.$refs.select_goods.showGoodsList();
    },
    getSelectedGoods(val) {
      //获取选中的货品
      let selected_goods = val;
      let set_goods = this.add_item.data.goods_set;

      if (selected_goods.length) {
        for (let i = 0, len = selected_goods.length; i < len; i++) {
          let added_flag = false;

          //排除已添加的
          for (let j = 0, jlen = set_goods.length; j < jlen; j++) {
            if (set_goods[j].id == selected_goods[i].id) {
              added_flag = true;
              break;
            }
          }

          //如果未添加
          if (!added_flag) {
            selected_goods[i].transfer_num = '';
            set_goods.push(selected_goods[i]);
          }
        }
      }
    },
    pageSizeChange(page_size) {
      this.list.size = page_size;
      localStorage.setItem(this.list.page_size_key, page_size);
      this.getMainList();
    },
    changePage(page) {
      this.list.page = page;
      this.getMainList();
    },
    editItem(row) {
      let param = {
        transfer_id: row.id,
      };
      this.add_item.data.main_no = row.main_no;
      this.add_item.data.id = row.id;
      this.add_item.data.target_house_id = row.target_house_id;
      this.add_item.data.from_house_id = row.from_house_id;
      this.add_item.data.remark = row.remark;
      this.add_item.data.operator_name = row.operator_name;
      getStockTransferDetail(param).then(res => {
        for (let i=0; i<res.data.results.length; i++) {
          res.data.results[i].house_num = parseFloat(res.data.results[i].house_num);
          res.data.results[i].transfer_num = parseFloat(res.data.results[i].transfer_num);
        }
        this.add_item.data.goods_set = res.data.results;
        this.add_item.modal_loading = false;
        this.add_item.modal = true;
      });
    },
    deleteItem(row) {
      this.$Modal.confirm({
        title: "删除调拨单信息",
        content: '确定删除调拨单信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.deleteStockTransfer(row);
        },
        onCancel: () => {
        }
      });
    },
    deleteStockTransfer(row) {
      let param = {
        transfer_id : row.id,
        main_no: row.main_no
      }
      deleteStockTransfer(param).then(res => {
        if (res.data.err_no == 0) {
          this.$Message.success(res.data.results);
          this.getMainList();
        } else {
          this.$Message.error(res.data.err_msg);
        }
      });
    },
    itemCreate(name) {
      this.$refs[name].resetFields();
      this.add_item.data.goods_set = [];

      getStockTransferMainNo().then(res => {
        this.add_item.data.main_no = res.data.results;
        this.add_item.modal = true;
        this.add_item.modal_loading = false;
      });
    },
    startItem(row) {//执行库存调拨
      this.$Modal.confirm({
        title: "调拨单执行信息",
        content: '确定执行调拨单',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          let param = {
            transfer_id: row.id,
            main_no: row.main_no,
            from_house_id: row.from_house_id
          };
          startStockTransfer(param).then(res => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              this.getMainList();
            } else {
              this.$Message.error(res.data.err_msg);
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    goodsInItem(row) {
      this.$Modal.confirm({
        title: "调拨单执行信息",
        content: '货品已从源库出库，确定转入目标仓库？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          let param = {
            transfer_id: row.id,
            main_no: row.main_no,
            target_house_id: row.target_house_id
          };
          goodsInStockTransfer(param).then(res => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              this.getMainList();
            } else {
              this.$Message.error(res.data.err_msg);
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    viewItem(row) {
      let param = {
        transfer_id: row.id,
      };
      this.view_item.data.main_no = row.main_no;
      this.view_item.data.id = row.id;
      this.view_item.data.target_house_id = row.target_house_id;
      this.view_item.data.from_house_id = row.from_house_id;
      this.view_item.data.remark = row.remark;
      this.view_item.data.operator_name = row.operator_name;
      this.view_item.data.status = row.status;
      this.view_item.data.updated_at = row.updated_at;
      getStockTransferDetail(param).then(res => {
        for (let i=0; i<res.data.results.length; i++) {
          res.data.results[i].house_num = parseFloat(res.data.results[i].house_num);
          res.data.results[i].transfer_num = parseFloat(res.data.results[i].transfer_num);
        }
        this.view_item.data.goods_set = res.data.results;
        this.view_item.modal = true;
      });
    },
    getMainList() {
      let param = {
        page: this.list.page,
        main_no: this.list.search_item.main_no,
        target_house_id: this.list.search_item.target_house_id,
        from_house_id: this.list.search_item.from_house_id,
        created_at: this.list.search_item.created_at,
        page_size: this.list.size,
      };

      getStockTransferList(param).then((res) => {
        this.list.data = res.data.results.list;
        this.list.total = res.data.results.total;
        this.list.size = res.data.results.size;
      });
    },
    setSelectedPageSize() {
      let size = localStorage.getItem(this.list.page_size_key);
      this.list.size = size ? (size > 50 ? 40 : parseInt(size)) : 0;
    },
  },
  mounted() {
    warehouseUtil.getWarehouseAll().then(res => {
       this.common.warehouse_all = res;
    });

    this.setSelectedPageSize();

    this.getMainList();
  },
  created() {
    this.aCheck.plateName = 'product_manage';
  },
  components: {
    SelectGoods,
  }
}
</script>

<style scoped>
#stock-transfer .ivu-card {
  background-color: #fafafa;
}

#stock-transfer .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#stock-transfer .ivu-btn-small {
  font-size: 12px !important;
}

#list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#list .page {
  margin-top: 16px;
  text-align: right;
}

.item_create {
  position: absolute;
  right: 0;
  top: -8px;
}
</style>

<style>
#stock-transfer .ivu-form-item {
  margin-bottom: 0;
}
</style>
