<template>
  <div id="goods_in" class="module-body">
    <div id="panel-header" style="display:flex; justify-content:space-between; align-items:center;">
      <div>
        <span class="top-title">入库列表</span>
      </div>
      <div v-if="aCheck.rightCheck('operate_warehouse_in')">
        <Button type="primary" class="ml-10" icon="md-add" @click="goodsInCreate('clientFormItem')">
          快捷入库
        </Button>
      </div>
    </div>
    <!--列表筛选条件-->
    <div id="filter-bar" class="filter-bar">
      <Card style="width:100%" dis-hover>
        <Form ref="list.search_item" :model="list.search_item" inline>
          <FormItem prop="main_no">
            <Input @on-blur="searchSubmit" v-model="list.search_item.main_no" placeholder="请填写入库编号" class="w-200"/>
          </FormItem>

          <FormItem prop="status">
            <Select @on-change="searchSubmit" v-model="list.search_item.status" placeholder="请选择入库状态" filterable class="w-200">
              <Option v-for="item in list.all_status" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>

          <FormItem prop="link_no">
            <Input @on-blur="searchSubmit" v-model="list.search_item.link_no" placeholder="请填写关联单号" class="w-200"/>
          </FormItem>

          <FormItem prop="created_at">
            <DatePicker type="daterange" class="w-200" @on-change="timeChange" placement="bottom-end" placeholder="请选择创建时间" v-model="list.search_item.created_at"></DatePicker>
          </FormItem>

          <FormItem>&nbsp;
            <Button type="text" class="main-font-color" @click="cancelSearch('list.search_item')">
              清除
            </Button>
          </FormItem>
        </Form>
      </Card>
    </div>

    <!--列表-->
    <div id="list" class="goods_in">
      <Table :columns="list.columns_info" :data="list.data">
        <template slot-scope="{ row }" slot="main_no">
          <strong @click="checkOrderInfo(row)" class="pointer">{{ row.main_no }}</strong>
        </template>
        <template slot-scope="{ row }" slot="status">
          <span :style="getStatusColor(row.status)">{{ getStatusText(row.status) }}</span>
        </template>
        <template slot-scope="{ row }" slot="in_type">
          <span>{{ getInTypeText(row.in_type) }}</span>
        </template>
        <template slot-scope="{ row }" slot="created_at">
          <span>{{ row.created_at.substring(0, 10) }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="action">
          <!--审核通过情况下显示-->
          <Button v-show="[1,2].includes(row.status)" type="text" size="small" v-if="aCheck.rightCheck('operate_warehouse_in')"
                  :style="getStatusColor(row.status)" @click="checkOrderInfo(row)">
            <Icon type="ios-checkmark-circle"/>
            上架
          </Button>
          <!--上架完成-->
          <Button v-show="row.status==3" type="text" size="small" :style="getStatusColor(row.status)"
                  @click="checkOrderInfo(row)">
            <Icon type="ios-checkmark-circle"/>
            查看
          </Button>
        </template>
      </Table>
      <div class="page">
        <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"
              :current="list.page" show-sizer @on-page-size-change="pageSizeChange" :page-size-opts="list.page_size_opts"/>
      </div>

    </div>

    <!--查看上架信息-->
    <Modal v-model="check_order.modal" :title="check_order.modal_title" width="1128">
      <div class="modalForm">
        <Spin size="large" fix v-show="check_order.spin_show"></Spin>
        <Form ref="clientFormItem" :model="clientFormItem" :label-width="100" label-colon>
          <Row :gutter="20">
            <Col span="8">
              <FormItem label="入库单号" prop="main_no">
                <span>{{ clientFormItem.main_no }}</span>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="入库类型" prop="in_type">
                <span>{{ getInTypeText(clientFormItem.in_type) }}</span>
              </FormItem>
            </Col>

            <Col span="8">
              <FormItem label="入库数量" prop="in_num">
                <span>{{ clientFormItem.in_num }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="8">
              <FormItem label="关联单号" prop="link_no">
                <span>{{ clientFormItem.link_no }}</span>
              </FormItem>
            </Col>

            <Col span="8">
              <FormItem label="备注" prop="remark">
                <span>{{ clientFormItem.remark }}</span>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="单据状态" prop="status">
                <span :style="getStatusColor(clientFormItem.status)">{{ getStatusText(clientFormItem.status) }}</span>
              </FormItem>
            </Col>
          </Row>

          <Row :class="clientFormItem.detail.inStartList.length ? 'mt2 display-block':''" v-show="clientFormItem.detail.inStartList.length">
            <Card dis-hover>
              <p slot="title">待上架货品</p>
              <Button type="primary" slot="extra" size="small" @click="listInBulk" class="f12" v-if="aCheck.rightCheck('operate_warehouse_in')">
                <Icon type="ios-checkmark-circle"/>
                批量上架
              </Button>
              <Table :columns="check_order.in_start_table" :data="clientFormItem.detail.inStartList" @on-selection-change="getSelectionChange">
                <template slot-scope="{ row }" slot="part_no">
                  <p><span>{{row.part_no}}</span></p>
                </template>

                <template slot-scope="{ row }" slot="thumbnail_url">
                  <p><img v-show="row.thumbnail_url" class="preview-img" :src="row.thumbnail_url" alt=""></p>
                </template>

                <template slot-scope="{ row }" slot="goods_type">
                  <p>{{ getGoodsType(row.goods_type) }}</p>
                </template>

                <template slot-scope="{ row }" slot="action">
                  <Button type="primary" size="small" @click="pushInHousePosition(row, clientFormItem.id)" v-if="aCheck.rightCheck('operate_warehouse_in')">
                    <Icon type="ios-checkmark-circle"/>
                    上架
                  </Button>
                </template>
              </Table>
            </Card>
          </Row>

          <Row :class="clientFormItem.detail.inEndList.length ? 'mt5 display-block':''" v-show="clientFormItem.detail.inEndList.length">
            <Card dis-hover>
              <p slot="title">已上架货品</p>
              <Table :columns="check_order.in_end_table" :data="clientFormItem.detail.inEndList">
                <template slot-scope="{ row }" slot="thumbnail_url">
                  <p><img v-show="row.thumbnail_url" class="preview-img" :src="row.thumbnail_url" alt=""></p>
                </template>

                <template slot-scope="{ row }" slot="goods_type">
                  <p>{{ getGoodsType(row.goods_type) }}</p>
                </template>

                <template slot-scope="{ row }" slot="house_info">
                  <Breadcrumb separator=">">
                    <BreadcrumbItem class="fs-12">{{ row.house_name }}</BreadcrumbItem>
                    <BreadcrumbItem class="fs-12">{{ row.area_name }}</BreadcrumbItem>
                    <BreadcrumbItem class="fs-12">{{ row.position_name }}</BreadcrumbItem>
                  </Breadcrumb>
                </template>
              </Table>
            </Card>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleResetOrderModal(clientFormItem)">关闭</Button>
      </div>
    </Modal>

    <!--上架操作-->
    <Modal v-model="push_in.modal" :title="push_in.modal_title" width="1128">
      <div class="modalForm">
        <Form ref="push_in.item" :model="push_in.item" :label-width="120" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="货品编号" prop="part_no">
                <span>{{ push_in.item.part_no }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="货品名称" prop="part_name">
                <span>{{ push_in.item.part_name }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="缩略图" prop="thumbnail_url">
                <p><img :src="push_in.item.thumbnail_url" alt=""></p>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="待上架数量" prop="goods_num">
                <span>{{ push_in.item.goods_num }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="上架仓库">
                <Select v-model="push_in.item.house_id" placeholder="请选择仓库"
                        @on-change="getHouseAreaAll">
                  <Option v-for="o in common.house_all" :value="o.house_id" :key="o.house_id">{{ o.house_name }}({{ o.house_no }})</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="上架库区">
                <Select v-model="push_in.item.area_id" placeholder="请先选择仓库"
                        @on-change="getHousePositionAll">
                  <Option v-for="item in common.area_all" :value="item.area_id" :key="item.area_id">{{ item.area_name }}({{ item.area_no }})</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="上架库位">
                <Select v-model="push_in.item.position_id" placeholder="请先选择仓库和库区">
                  <Option v-for="o in common.position_all" :value="o.position_id"
                          :key="o.position_id">{{ o.position_name }}({{ o.position_no }})</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="上架数量">
                <Input v-model="push_in.item.in_num" type="number" placeholder="请填写入库数量"/>
              </FormItem>
            </Col>
          </Row>

          <Row class="mt2 display-block" v-show="stockInfoList.length">
            <Card dis-hover>
              <p slot="title">货品仓库位置列表</p>
              <Table :columns="goods_stock_position_info.in_already_table" :data="stockInfoList">
                <template slot-scope="{ row }" slot="thumbnail_url">
                  <p><img v-show="row.thumbnail_url" class="preview-img" :src="row.thumbnail_url" alt=""></p>
                </template>
              </Table>
            </Card>
          </Row>

        </Form>
      </div>
      <div slot="footer">
        <Button @click="pushInModalCancel(push_in.item)">关闭</Button>
        <Button type="success" :loading="push_in.modal_loading" @click="pushInSure(push_in.item)">确认上架</Button>
      </div>
    </Modal>

    <!--批量上架操作-->
    <Modal v-model="push_in_batch.modal" :title="push_in_batch.modal_title" width="1128">
      <div class="modalForm">
        <Form ref="push_in.item" :model="push_in.item" :label-width="120" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="上架仓库">
                <Select v-model="push_in_batch.item.house_id" placeholder="请选择仓库"
                        @on-change="getHouseAreaAll">
                  <Option v-for="o in common.house_all" :value="o.house_id" :key="o.house_id">
                    {{ o.house_name }}({{ o.house_no }})
                  </Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="上架库区">
                <Select v-model="push_in_batch.item.area_id" placeholder="请先选择仓库"
                        @on-change="getHousePositionAll">
                  <Option v-for="item in common.area_all" :value="item.area_id" :key="item.area_id">
                    {{ item.area_name }}({{ item.area_no }})
                  </Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="上架库位">
                <Select v-model="push_in_batch.item.position_id" placeholder="请先选择仓库和库区" @on-change="setBatchGoodsInPosition">
                  <Option v-for="o in common.position_all" :value="o.position_id"
                          :key="o.position_id">{{ o.position_name }}({{ o.position_no }})
                  </Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
        </Form>
        </div>
      <div slot="footer">
        <Button @click="pushInModalCancel">关闭</Button>
        <Button type="success" :loading="push_in_batch.modal_loading" @click="pushInBatchSure">确认上架</Button>
      </div>
    </Modal>

    <!--添加快捷入库-->
    <Modal v-model="goods_in_create.modal" :title="goods_in_create.modal_title" width="1128">
      <div class="modalForm">
        <Form v-show="!common.view_loading" ref="clientFormItem" :model="goods_in_create.item" :label-width="100" :rules="goods_in_create.ruleInline" label-colon>
          <Row :gutter="20">
            <Col span="8">
              <FormItem label="入库单号" prop="main_no">
                <Input v-model="goods_in_create.item.main_no" disabled placeholder="请填写入库单号"></Input>
              </FormItem>
            </Col>

            <Col span="8">
              <FormItem label="入库类型" prop="in_type">
                <span>{{ getInTypeText(goods_in_create.item.in_type) }}</span>
              </FormItem>
            </Col>

            <Col span="8">
              <FormItem label="入库数量" prop="in_num">
                <span>{{ goods_in_create.item.in_num }}</span>
              </FormItem>
            </Col>
          </Row>

          <Row :gutter="20">
              <Col span="8">
                <FormItem label="上架仓库">
                  <Select v-model="goods_in_create.item.house_id" placeholder="请选择仓库" @on-change="getHouseAreaAll">
                    <Option v-for="o in common.house_all" :value="o.house_id" :key="o.house_id">
                      {{ o.house_name }}({{ o.house_no }})
                    </Option>
                  </Select>
                </FormItem>
              </Col>

              <Col span="8">
                <FormItem label="上架库区">
                  <Select v-model="goods_in_create.item.area_id" placeholder="请先选择仓库" @on-change="getHousePositionAll">
                    <Option v-for="item in common.area_all" :value="item.area_id" :key="item.area_id">
                      {{ item.area_name }}({{ item.area_no }})
                    </Option>
                  </Select>
                </FormItem>
              </Col>

              <Col span="8">
                <FormItem label="上架库位">
                  <Select v-model="goods_in_create.item.position_id" placeholder="请先选择仓库和库区" @on-change="setBatchGoodsInPosition">
                    <Option v-for="o in common.position_all" :value="o.position_id"
                            :key="o.position_id">{{ o.position_name }}({{ o.position_no }})
                    </Option>
                  </Select>
                </FormItem>
              </Col>
          </Row>

          <Row :gutter="20">
            <Col span="8">
              <FormItem label="备注" prop="remark">
                <Input v-model="goods_in_create.item.remark" placeholder="请填写备注"></Input>
              </FormItem>
            </Col>
          </Row>

          <Row class="mt2 display-block">
            <Card dis-hover>
              <p slot="title">待上架货品</p>
              <Button type="primary" slot="extra" size="small" @click="addGoods()" class="f12">
                <Icon type="md-add"/>
                选择货品
              </Button>
              <Table :columns="goods_in_create.columns" :data="goods_in_create.item.goods_set">
                <template slot-scope="{ row }" slot="part_no">
                  <p><span>{{row.part_no}}</span></p>
                </template>
                <template slot-scope="{ row }" slot="thumbnail_url">
                  <p><img v-show="row.thumbnail_url" class="preview-img" :src="row.thumbnail_url" alt=""></p>
                </template>

                <template slot-scope="{ row, index }" slot="goods_num">
                  <p>
                    <i-input type="number" v-model="row.goods_num" @on-change="setGoodsInNum(row, index)"/>
                  </p>
                </template>

                <template slot-scope="{ row, index }" slot="action">
                  <Button type="text" size="small" class="fs-12" @click="deleteGoodsSelected(row, index)">
                    <Icon type="ios-trash"/>
                    删除
                  </Button>
                </template>
              </Table>
            </Card>
          </Row>
        </Form>
        <Spin fix v-show="common.view_loading" size="large"></Spin>
      </div>
      <div slot="footer">
        <Button @click="handleReset()">关闭</Button>
        <Button type="primary" :loading="goods_in_create.modal_loading" @click="saveGoodsIn()">保存
        </Button>
      </div>
    </Modal>

    <SelectGoods ref="select_goods" @selected="getSelectedGoods" :select_goods="select_goods"/>


  </div>
</template>

<script>
import SelectGoods from '@/components/common/SelectGoods.vue';
import NP from 'number-precision';

import {
  pushInBatch,
  getGoodsInMainNO,
  goodsInByHands
} from '@/api/goods/goodsIn';

export default {
  name: "GoodsIn",
  data() {
    return {
      select_goods: {
        modal: false,
        count_flag: 0,
        goods_type: 0
      },
      goods_in_create: {
        modal: false,
        modal_title: "快捷入库操作",
        item: {
          main_no: '',
          in_type: 5,
          in_num: 0,
          remark: '',
          goods_set: [],
          house_id: 0,
          area_id: 0,
          position_id: 0,
        },
        ruleInline: {},
        modal_loading: false,
        columns: [
          {
            title: '货品编号',
            key: 'part_no',
            slot: 'part_no',
            align: 'center'
          },
          {
            title: '货品名称',
            key: 'part_name',
            align: 'center'
          },
          {
            title: '缩略图',
            key: 'thumbnail_url',
            slot: 'thumbnail_url',
            align: 'center'
          },
          {
            title: '待入库数量',
            key: 'goods_num',
            slot: 'goods_num',
            align: 'center'
          },
          {
            title: '仓库库存',
            key: 'stock_all',
            align: 'center'
          },
          {
            title: '操作',
            key: 'action',
            slot: 'action',
            align: 'center'
          }
        ],
      },
      clientFormItem: {
        id: '',
        main_no: '',
        in_type: '',
        link_no: '',
        link_house_id: 0,
        link_id:0,
        in_num: '',
        status: '',
        remark: '',
        detail: {
          inStartList: [],
          inEndList: [],
        },
      },
      stockInfoList: [],

      //查看上架细节信息
      check_order: {
        modal: false,
        spin_show: false,
        modal_title: '查看订单信息',
        in_start_table: [
          {
            type: 'selection',
            width: 60,
            align: 'center'
          },
          {
            title: '货品编号',
            key: 'part_no',
            slot: 'part_no',
            align: 'center'
          },
          {
            title: '货品名称',
            key: 'part_name',
            align: 'center'
          },
          {
            title: '缩略图',
            key: 'thumbnail_url',
            slot: 'thumbnail_url',
            align: 'center'
          },
          {
            title: '货品类型',
            key: 'goods_type',
            slot: 'goods_type',
            align: 'center'
          },
          {
            title: '待入库数量',
            key: 'goods_num',
            align: 'center'
          },
          {
            title: '仓库库存',
            key: 'stock_all',
            align: 'center'
          },
          {
            title: '操作',
            key: 'action',
            slot: 'action',
            align: 'center'
          }
        ],
        in_end_table: [
          {
            title: '货品编号',
            key: 'part_no',
            align: 'center'
          },
          {
            title: '货品名称',
            key: 'part_name',
            align: 'center'
          },
          {
            title: '缩略图',
            key: 'thumbnail_url',
            slot: 'thumbnail_url',
            align: 'center'
          },
          {
            title: '货品类型',
            key: 'goods_type',
            slot: 'goods_type',
            align: 'center'
          },
          {
            title: '已入库数量',
            key: 'num',
            align: 'center'
          },
          {
            title: '仓库位置',
            key: 'house_info',
            slot: 'house_info',
            width: 240,
            align: 'center'
          },
        ],
      },
      goods_stock_position_info: {
        modal: false,
        spin_show: false,
        modal_title: '查看订单信息',
        in_already_table: [
          {
            title: '货品编号',
            key: 'part_no',
            align: 'center'
          },
          {
            title: '货品名称',
            key: 'part_name',
            align: 'center'
          },
          {
            title: '缩略图',
            key: 'thumbnail_url',
            slot: 'thumbnail_url',
            align: 'center'
          },
          {
            title: '库存',
            key: 'num',
            align: 'center'
          },
          {
            title: '仓库',
            key: 'house_name',
            align: 'center'
          },
          {
            title: '库区',
            key: 'area_name',
            align: 'center'
          },
          {
            title: '库位',
            key: 'position_name',
            align: 'center'
          },

        ],
      },
      //上架操作
      push_in: {
        modal: false,
        modal_title: '入库上架操作',
        modal_loading: false,
        item: {
          house_id: '',//仓库
          area_id: '',//库区
          position_id: '',//库位
          part_no: '',//货品编号
          part_name: '',//货品名称
          thumbnail_url: '',//缩略图
          goods_num: '',//待上架数量
          goods_in_id: '',//入库单ID
          goods_id: '',//货品ID
          in_num: '',//入库数量
        }
      },
      push_in_batch: {
        modal: false,
        modal_title: '批量入库上架操作',
        modal_loading: false,
        item: {
          goods_in_id: 0,
          house_id: '',//仓库
          area_id: '',//库区
          position_id: '',//库位
          goods_info: [],//货品信息
        }
      },
      //通用信息
      common: {
        house_all: [],
        area_all: [],
        position_all: [],
        view_loading: false,
      },

      //列表
      list: {
        columns_info: [
          {
            title: '入库单号',
            key: 'main_no',
            slot: 'main_no',
            align: 'center'
          },
          {
            title: '入库状态',
            key: 'status',
            slot: 'status',
            align: 'center'
          },
          {
            title: '入库数量',
            key: 'in_num',
            align: "center",
          },
          {
            title: '入库类型',
            key: 'in_type',
            slot: 'in_type',
            align: 'center'
          },
          {
            title: '关联单号',
            key: 'link_no',
            width: 180,
            align: 'center'
          },
          {
            title: '创建时间',
            slot: 'created_at',
            key: 'created_at',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
            width: 160,
          }
        ],
        data: [],
        total: 0,
        size: 0,
        page_size_key: 'goods_in_page_size_key',
        page_size_opts: [10, 15, 20, 30],
        search_item: {
          main_no: '',
          in_type: '',
          created_at: '',
          link_no: '',
        },
        all_status: [
          {
            "id": 1,
            "name": '待上架'
          },
          {
            "id": 2,
            "name": '上架中'
          },
          {
            "id": 3,
            "name": '已上架'
          },
        ],
        page: 1,
      },
      create: {
        model_title: '创建入库单',
        modal_loading: false,
        modal: false,
      }
    };
  },
  methods: {
    getGoodsType(type) {
      let name = '';
      switch (type) {
        case 1:
          name = '物料';
          break;
        case 2:
          name = '成品';
          break;
        case 3:
          name = '半成品';
          break;
        case 4:
          name = '虚拟';
          break;
      }
      return name;
    },
    getSelectionChange(item) {
      this.push_in_batch.item.goods_info = [];
      for (let i=0; i<item.length; i++) {
        let tmp = {
          goods_id : item[i].goods_id,
          goods_num : item[i].goods_num,
        };
        this.push_in_batch.item.goods_info.push(tmp);
      }
    },
    saveGoodsIn() {
      this.goods_in_create.modal_loading = true;
      if (this.goods_in_create.item.goods_set.length <= 0) {
        this.$Message.error('请选择需要上架的货品！');
        this.goods_in_create.modal_loading = false;
        return false;
      }

      for (let i = 0; i < this.goods_in_create.item.goods_set.length; i++) {
        if (this.goods_in_create.item.goods_set[i].goods_num == undefined || parseFloat(this.goods_in_create.item.goods_set[i].goods_num) <=0 ) {
          this.$Message.error('请正确填写上架货品的数量，不能为空和负数！');
          this.goods_in_create.modal_loading = false;
          return false;
        }
      }

      goodsInByHands(this.goods_in_create.item).then(res => {
        if (res.data.err_no == 0) {
          this.$Message.success(res.data.results);
          this.goods_in_create.modal_loading = false;
          this.goods_in_create.modal = false;

          this.getOrderList();
        } else {
          this.$Message.error(res.data.err_msg);
          this.goods_in_create.modal_loading = false;
        }
      }).catch(err => {
        this.$Message.error('操作异常');
        this.goods_in_create.modal_loading = false;
      })
    },
    setGoodsInNum(row, index) {
      this.goods_in_create.item.goods_set[index].goods_num = row.goods_num;
      this.setGoodsInNumTotal();
    },
    deleteGoodsSelected(row, index) {
      this.goods_in_create.item.goods_set.splice(index, 1);
      this.setGoodsInNumTotal();
    },
    setGoodsInNumTotal() {
      let total = 0;
      for (let i = 0; i < this.goods_in_create.item.goods_set.length; i++) {
        if (this.goods_in_create.item.goods_set[i].goods_num !== undefined) {
          total =  NP.plus(total, this.goods_in_create.item.goods_set[i].goods_num);
        }
      }

      this.goods_in_create.item.in_num = total;
    },
    //获取选中的货品
    getSelectedGoods(val) {
      //设置选中的物料
      let selected_goods = val;
      let set_goods = this.goods_in_create.item.goods_set;

      if (selected_goods.length) {
        for (let i = 0, len = selected_goods.length; i < len; i++) {
          let added_flag = false;

          //排除已添加的
          for (let j = 0, jlen = set_goods.length; j < jlen; j++) {
            if (set_goods[j].id == selected_goods[i].id) {
              added_flag = true;
              break;
            }
          }

          //如果未添加
          if (!added_flag) {
            set_goods.push(selected_goods[i]);
          }
        }
      }
    },
    addGoods() {//添加物料
      this.$refs.select_goods.select_goods.goods_type = this.select_goods.goods_type;
      this.$refs.select_goods.showGoodsList();
    },
    goodsInCreate(name) {
      this.goods_in_create.modal = true;
      this.goods_in_create.item.goods_set = [];
      this.goods_in_create.item.remark = '';
      getGoodsInMainNO().then(res => {
        if (res.data.err_no == 0) {
          this.goods_in_create.item.main_no = res.data.results;
        }
      })

      this.goods_in_create.item.house_id = 0;
      this.goods_in_create.item.area_id = 0;
      this.goods_in_create.item.position_id = 0;
    },
    listInBulk() {
      //判断是否有选中
      if (this.push_in_batch.item.goods_info.length<=0) {
        // this.$Message.error('请选择上架货品！');
        // return;
        for (let i=0; i<this.clientFormItem.detail.inStartList.length; i++) {
          let tmp = {
            goods_id : this.clientFormItem.detail.inStartList[i].goods_id,
            goods_num : this.clientFormItem.detail.inStartList[i].goods_num,
          };
          this.push_in_batch.item.goods_info.push(tmp);
        }
      }

      this.push_in_batch.item.goods_in_id = this.clientFormItem.id;
      if (this.clientFormItem.link_house_id) {
        this.push_in_batch.item.house_id = this.clientFormItem.link_house_id;
        this.push_in_batch.item.area_id = '';
        this.push_in_batch.item.position_id = '';
      } else {
        this.push_in_batch.item.house_id = parseFloat(this.getStorageBatchGoodsIn('house'));
        this.push_in_batch.item.area_id = parseFloat(this.getStorageBatchGoodsIn('area'));
        this.push_in_batch.item.position_id = parseFloat(this.getStorageBatchGoodsIn('position'));
      }

      if (this.push_in_batch.item.house_id) {
        this.getHouseAreaAll(this.push_in_batch.item.house_id)
      }

      if (this.push_in_batch.item.area_id) {
        this.getHousePositionAll(this.push_in_batch.item.area_id)
      }

      this.push_in_batch.modal = true;
      this.push_in_batch.modal_loading = false;
    },
    pageSizeChange(page_size) {
      this.list.size = page_size;
      localStorage.setItem(this.list.page_size_key, page_size);
      this.getOrderList();
    },
    //获取入库来源文案
    getInTypeText($in_type) {
      let rs = '';
      switch ($in_type) {
        case 1:
          rs = '采购单';
          break;
        case 2:
          rs = '销售退货';
          break;
        case 3:
          rs = '调拨单';
          break;
        case 4:
          rs = '生产单';
          break;
        case 5:
          rs = '快捷';
          break;
        case 6:
          rs = '领料退库';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getStatusColor(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = 'color:#ed4014';
          break;
        case 2:
          rs = 'color:#2775ff';
          break;
        case 3:
          rs = 'color:#19be6b';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getStatusText(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = '待上架';
          break;
        case 2:
          rs = '上架中';
          break;
        case 3:
          rs = '已上架';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },

    //弹出框重置
    handleReset() {
      this.goods_in_create.modal = false;
    },

    //获取入库单信息
    getGoodsInOrderById($goods_in_id) {
      this.check_order.spin_show = true;
      this.$axios.get('/api/goods/getGoodsInOrderById?id=' + $goods_in_id)
          .then((response) => {
            if (response.data.err_no == 0) {
              let result = response.data.results;
              this.clientFormItem.id = result.id;
              this.clientFormItem.main_no = result.main_no;
              this.clientFormItem.in_type = result.in_type;
              this.clientFormItem.link_no = result.link_no;
              this.clientFormItem.link_id = result.link_id;
              this.clientFormItem.link_house_id = result.link_house_id;
              this.clientFormItem.in_num = parseFloat(result.in_num);
              this.clientFormItem.status = result.status;
              this.clientFormItem.remark = result.remark;

              for (let i = 0; i < result.detail.inStartList.length; i++) {
                result.detail.inStartList[i].goods_num = parseFloat(result.detail.inStartList[i].goods_num);
                result.detail.inStartList[i].stock_all = parseFloat(result.detail.inStartList[i].stock_all);
              }

              for (let i = 0; i < result.detail.inEndList.length; i++) {
                result.detail.inEndList[i].num = parseFloat(result.detail.inEndList[i].num);
                result.detail.inEndList[i].stock_all = parseFloat(result.detail.inEndList[i].stock_all);
              }
              this.clientFormItem.detail.inStartList = result.detail.inStartList;
              this.clientFormItem.detail.inEndList = result.detail.inEndList;
              this.check_order.spin_show = false;
            }
          });
    },

    //列表搜索
    searchSubmit() {
      this.list.page = 1;
      this.getOrderList();
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.list.page = 1;
      this.getOrderList();
    },

    //获取订单列表
    getOrderList() {
      let param = {
        page: this.list.page,
        main_no: this.list.search_item.main_no,
        status: this.list.search_item.status,
        created_at: this.list.search_item.created_at,
        link_no: this.list.search_item.link_no,
        size:this.list.size
      };
      this.$axios.post('/api/goods/getGoodsInList', param).then((res) => {
        if (res.data.err_no == 0) {
          for (let i = 0; i < res.data.results.list.length; i++) {
            res.data.results.list[i].in_num = parseFloat(res.data.results.list[i].in_num);
          }
          this.list.data = res.data.results.list;
          this.list.total = res.data.results.total;
          this.list.size = res.data.results.size;
        }
      });
    },
    changePage(page) {
      this.list.page = page;
      this.getOrderList();
    },

    //进行上架操作
    checkOrderInfo(row) {
      this.getGoodsInOrderById(row.id);
      this.push_in.modal_loading = false;
      this.check_order.modal = true;

      this.push_in_batch.item.goods_info = [];//批量上架数据清空
    },

    //弹出框关闭操作
    handleResetOrderModal(name) {
      this.check_order.modal = false;
    },
    pushInModalCancel() {
      this.push_in.modal = false;
      this.push_in_batch.modal = false;
    },

    //上架
    pushInHousePosition(row, goods_in_id) {
      this.push_in.item.area_id = '';
      this.push_in.item.house_id = this.clientFormItem.link_house_id;
      this.push_in.item.position_id = '';
      this.push_in.item.part_no = row.part_no;
      this.push_in.item.part_name = row.part_name;
      this.push_in.item.thumbnail_url = row.thumbnail_url;
      this.push_in.item.goods_num = row.goods_num;
      this.push_in.item.in_num = row.goods_num;
      this.push_in.item.goods_in_id = goods_in_id;
      this.push_in.item.goods_id = row.goods_id;
      this.push_in.modal_loading = false;
      this.push_in.modal = true;

      let param = {
        goods_id: row.goods_id,
      };
      this.$axios.post('/api/goods/getGoodsStockInfo', param).then((res) => {
        if (res.data.err_no === 0) {
          for (let i=0; i<res.data.results.list.length; i++) {
            res.data.results.list[i].num = parseFloat(res.data.results.list[i].num);
          }
          this.stockInfoList = res.data.results.list;
        } else {
          this.$Message.error(res.data.err_msg);
        }
      });

      if (this.push_in.item.house_id) {
        this.getHouseAreaAll(this.push_in.item.house_id)
      }
    },

    //批量确认上架
    pushInBatchSure() {
      this.push_in_batch.modal_loading = true;

      if (this.push_in_batch.item.goods_info.length< 0) {
        this.$Message.error('批量上架货品不能为空');
        this.push_in.modal_loading = false;
        return;
      }

      let param = {
        goods_in_id:this.push_in_batch.item.goods_in_id,
        position_id: this.push_in_batch.item.position_id,
        area_id: this.push_in_batch.item.area_id,
        house_id: this.push_in_batch.item.house_id,
        goods_info:this.push_in_batch.item.goods_info,
        main_no: this.clientFormItem.main_no
      };

      pushInBatch(param).then(response => {
        if (response.data.err_no === 0) {
          this.$Message.success(response.data.results);

          //更新上架情况
          this.getGoodsInOrderById(this.push_in_batch.item.goods_in_id);

          this.push_in_batch.modal = false;
          //更新列表信息
          this.getOrderList();
        } else {
          this.$Message.error(response.data.err_msg);
          this.push_in_batch.modal_loading = false;
        }
      }).catch(error => {
        this.$Message.error('操作错误');
        this.push_in_batch.modal_loading = false;
      });
    },
    //确认上架
    pushInSure($item) {
      this.push_in.modal_loading = true;

      //检查上架数量是否大于入库数量
      if (parseInt($item.in_num) > parseInt($item.goods_num)) {
        this.$Message.error('上架数量不能大于入库数量');
        this.push_in.modal_loading = false;
        return;
      }

      let param = {
        goods_in_id: $item.goods_in_id,
        goods_id: $item.goods_id,
        house_id: $item.house_id,
        area_id: $item.area_id,
        position_id: $item.position_id,
        num: $item.in_num,
        main_no: this.clientFormItem.main_no
      };

      this.$axios.post('/api/goods/pushInSure', param).then((response) => {
            if (response.data.err_no === 0) {
              this.$Message.success(response.data.results);

              //更新上架情况
              this.getGoodsInOrderById($item.goods_in_id);

              this.push_in.modal = false;
              //更新列表信息
              this.getOrderList();
            } else {
              this.$Message.error(response.data.err_msg);
              this.push_in.modal_loading = false;
            }

          }).catch(error => {
          this.$Message.error('操作错误');
          this.push_in.modal_loading = false;
      });
    },

    //获取所有可用仓库列表
    getWarehouseAll: function () {
      this.$axios.get('/api/setting/getWarehouseAll').then((response) => {
        if (response.data.err_no === 0) {
          this.common.house_all = response.data.results;
        }
      });
    },
    getStorageBatchGoodsIn(key) {
      let rs = '';
      switch (key) {
        case 'house':
          rs = localStorage.getItem('batch_goods_in_house');
          break;
        case 'area':
          rs = localStorage.getItem('batch_goods_in_area');
          break;
        case 'position':
          rs = localStorage.getItem('batch_goods_in_position');
          break;
      }

      return rs;
    },
    setStorageBatchGoodsIn(key, value) {
      switch (key) {
        case 'house':
          localStorage.setItem('batch_goods_in_house', value);
          break;
        case 'area':
          localStorage.setItem('batch_goods_in_area', value);
          break;
        case 'position':
          localStorage.setItem('batch_goods_in_position', value);
          break;
      }
    },
    //获取仓库所有库区
    getHouseAreaAll($house_id) {
      if (this.clientFormItem.link_house_id && $house_id != this.clientFormItem.link_house_id) {
        this.$Message.error('入库仓库已指定，请不要随意更改，以免造成入库异常！！！');
        this.push_in.item.house_id = this.clientFormItem.link_house_id;
        this.push_in_batch.item.house_id = this.clientFormItem.link_house_id;
        return false;
      }

      this.$axios.get('/api/setting/getHouseAreaAll?house_id=' + $house_id).then((response) => {
        if (response.data.err_no === 0) {
          this.push_in.item.area_id = '';
          this.push_in.item.position_id = '';
          this.common.area_all = response.data.results;
        }
      });
    },
    //获取仓库对应库区的所有库位
    getHousePositionAll: function ($area_id) {
      this.$axios.get('/api/setting/getHousePositionAll?area_id=' + $area_id).then((response) => {
        if (response.data.err_no === 0) {
          this.push_in.item.position_id = '';
          this.common.position_all = response.data.results;
        }
      });
    },
    setBatchGoodsInPosition: function (position_id) {
      this.setStorageBatchGoodsIn('house', this.push_in_batch.item.house_id)
      this.setStorageBatchGoodsIn('area', this.push_in_batch.item.area_id)
      this.setStorageBatchGoodsIn('position', position_id);
    },
    timeChange(dateRange) {
      if (dateRange[0] && dateRange[1]) {
        this.list.search_item.created_at = dateRange;
      } else {
        this.list.search_item.created_at = "";
      }

      this.searchSubmit();
    },
    setSelectedPageSize() {
      let size = localStorage.getItem(this.list.page_size_key);
      this.list.size = size ? (size > 50 ? 40 : parseInt(size)) : 0;
    },
  },
  components: {
    SelectGoods,
  },
  mounted() {
    this.setSelectedPageSize();

    this.getOrderList();

    this.getWarehouseAll();
  },
  created() {
    this.aCheck.plateName = 'product_manage';
  },
};
</script>

<style scoped>
#panel-header {
  border: none;
}

.modalForm {
  width: 100%;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

#goods_in .ivu-btn-small {
  font-size: 12px !important;
}

#list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#list .page {
  margin-top: 16px;
}

.goods-in-create {
  position: absolute;
  right: 0;
  top: -8px;
}
</style>
<style>
#goods_in .ivu-card {
  background-color: #fafafa;
}

#goods_in .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#goods_in .ivu-form-item {
  margin-bottom: 0;
}

#list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#list .ivu-page {
  float: right;
}

.goods_in .ivu-table-overflowX, .ivu-table-tip {
  overflow-x: hidden;
}
</style>
